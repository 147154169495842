import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SetPasswordFailurePageComponent } from './components/set-password-failure-page/set-password-failure-page.component';
import { SetPasswordSuccessPageComponent } from './components/set-password-success-page/set-password-success-page.component';
import { SetPasswordPageContainerComponent } from './container/set-password-page-container/set-password-page-container.component';

const routes: Routes = [
  { path: '', component: SetPasswordPageContainerComponent },
  {
    path: 'success',
    component: SetPasswordSuccessPageComponent,
  },
  {
    path: 'failure',
    component: SetPasswordFailurePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetPasswordPageRoutingModule {}
