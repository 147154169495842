<div class="set-pwd-failure__main-container">
  <div
    class="set-pwd-failure__main-container__img-container"
    [ngClass]="{ 'set-pwd-failure__main__img-bloc--myvetreco': env.isWhiteLabelMyVetReco }"
  >
    <img src="./assets/images/reset_password_error.jpg" alt="" />
  </div>
  <div class="set-pwd-failure__main-container__text-container">
    <h1 class="set-pwd-failure__main-container__text-container__h1">{{ 'setPasswordFailure_title' | translate }}</h1>

    <p class="set-pwd-failure__main-container__text-container__content-p">
      <ng-container *ngIf="hideSupportBt; then retry; else retry_or_contact"></ng-container>
      <ng-template #retry_or_contact> {{ 'setPasswordFailure_content' | translate }}</ng-template>
      <ng-template #retry> {{ 'reset_phoneValidationCodeError' | translate }}</ng-template>
    </p>

    <div class="set-pwd-failure__main-container__text-container__bottom">
      <button
        (click)="retryReset()"
        class="rc-button rc-button--outline-regular set-pwd-failure__main-container__text-container__bottom__button1"
      >
        <span class="rc-button__title">{{ 'setPasswordFailure_buttonTryAgain' | translate }}</span>
      </button>
      <button
        *ngIf="!hideSupportBt"
        class="rc-button rc-button--outline-regular set-pwd-failure__main-container__text-container__bottom__button2"
      >
        <app-contact-us-button labelKey="setPasswordFailure_buttonSupport"></app-contact-us-button>
      </button>
    </div>
  </div>
</div>
