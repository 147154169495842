import { Observable, Subject } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ScriptAttributes } from './dom.types';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(attributes: ScriptAttributes): Observable<boolean> {
    const result$ = new Subject<boolean>();
    const resultFactory = (result: boolean) => () => {
      result$.next(result);
      result$.complete();
    };

    const script = this.document.createElement('script');
    Object.keys(attributes).forEach((name) => script.setAttribute(name, attributes[name]));
    script.onload = resultFactory(true);
    script.onerror = resultFactory(false);
    this.document.body.appendChild(script);

    return result$.asObservable();
  }
}
