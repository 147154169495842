<div class="register-success-container">
  <div class="register-success-container__logo" [class.register-success-container__logo--whitelabel]="env.isWhiteLabel">
    <img *ngIf="env.isWhiteLabel" src="./assets/logo.svg" alt="" />
    <img *ngIf="env.isRoyalCanin" src="https://dl.royalcanin.com/8-12-1/logo--primary.svg" alt="" />
  </div>

  <div class="register-success-container__img-bloc">
    <img src="./assets/images/register_success.jpg" alt="" />
  </div>

  <div class="register-success-container__text-container">
    <h1 class="register-success-container__text-container__h1">{{ 'registerAppSuccess_title' | translate }}</h1>

    <p class="register-success-container__text-container__p">
      {{ 'registerAppSuccess_contentFirstLine' | translate }}
      <span class="bold-text" *ngIf="user && user.profile">{{ user.profile.email }}</span>
    </p>

    <p class="register-success-container__text-container__p">
      {{ 'registerAppSuccess_contentLastLine' | translate }}
      <a (click)="clickContact()" appTracking="registration.contactSupport" class="rc-link">{{ 'contactSupportPage_title' | translate }}</a>
    </p>
  </div>
</div>
