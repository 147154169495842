import { environment } from 'src/environments/environment';
import { AppTitle, AppType } from 'src/environments/environment.interface';
import { RootErrorPageInfos } from '@app/core/models';

function getDefaultContactSupport() {
  switch (environment.appType) {
    case AppType.RC:
      return 'ciam@royalcanin.zendesk.com';
    case AppType.MVR:
      return 'help@myvetreco.com';
    default:
      return 'ciam@royalcanin.zendesk.com';
  }
}

const CONTACT_PAGES: Record<string, string> = {
  HU: 'https://www.royalcanin.com/hu/contact-us',
};

const DEFAULT_CONTACT_SUPPORT = getDefaultContactSupport();

const DEFAULT_VET_CONTACT_PAGE = 'https://vetfocus.royalcanin.com/en/contact-us';

export const getContactSupportEmails = () => [DEFAULT_CONTACT_SUPPORT];

export const hasContactLink = (countryCode?: string) => !!(countryCode && CONTACT_PAGES[countryCode]);

export const getContactLink = (countryCode?: string, isVet?: boolean) => {
  return isVet ? DEFAULT_VET_CONTACT_PAGE : CONTACT_PAGES[countryCode];
};

export const buildMailtoHref = (emails: string[], subject: string, body: string): string => {
  const [recipient, ...to] = emails;
  const params = [`subject=${subject}`, `body=${body}`];
  if (to.length) {
    params.push(`to=${to.join(';')}`);
  }
  const label = !environment.isWhiteLabel ? AppTitle.ROYAL_CANIN : AppTitle.MY_VET_RECO;
  return encodeURI(`mailto:${label} <${recipient}>?${params.join('&')}`);
};

export interface ContactSupportInfos extends Partial<RootErrorPageInfos> {
  clientId: string;
  apiCodeError: string;
  apiUrlError: string;
  apiErrorReturn: string;
  redirectUri: string;
  userLocale: string;
  email?: string;
}
