import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { TrackingService } from 'src/app/core/services/tracking/tracking.service';
import { environment } from 'src/environments/environment';
import packageInfo from '../../package.json';
import { EnvironmentService } from './core/environment.service';
import { LocaleService } from './core/services';
import { FooterService } from './core/services/footer.service';
import { WINDOW } from './shared/providers/window.provider';
import { getSearchParamFromUrl } from './shared/utils';
import { FALLBACK_LANGUAGE, getCurrentPhraseLanguage } from '@app/shared/utils';
import { CookieSettingsButtonComponent } from './shared/components/cookie-settings-button/cookie-settings-button.component';
import { RcFooterComponent } from './shared/components/rc-footer/rc-footer.component';
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { MessageComponent } from '@app/shared/components/message/message.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NgIf, RouterOutlet, RcFooterComponent, CookieSettingsButtonComponent, MessageComponent],
})
export class AppComponent implements OnInit {
  public version = packageInfo.version;
  public isLanguageLoaded = false;
  public showCookiesButton = false;

  private phraseLanguage: string = FALLBACK_LANGUAGE;

  constructor(
    @Inject(WINDOW) private window: Window,
    private footerService: FooterService,
    private localeService: LocaleService,
    private translateService: TranslateService,
    private titleService: Title,
    private trackingService: TrackingService,
    public env: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.env.getAppTitle());
    this.setupLocales();
    this.loadServices();
  }

  setupLocales(): void {
    const clientId = getSearchParamFromUrl('client');
    const configuredLanguage = clientId && environment.languagesCombinations[clientId];
    const language = configuredLanguage || this.window.navigator.language || FALLBACK_LANGUAGE;
    this.phraseLanguage = getCurrentPhraseLanguage(language);

    this.localeService.setLanguage(language);

    this.window.document.documentElement.setAttribute('lang', this.phraseLanguage.split('-')[0]);
    this.translateService.setDefaultLang(this.phraseLanguage);
    this.translateService.use(this.phraseLanguage);

    this.translateService
      .get('registerApp_titleWelcome')
      .pipe(take(1))
      .subscribe(() => {
        this.isLanguageLoaded = true;
      });
  }

  loadServices(): void {
    const language = this.localeService.getLanguage();
    this.footerService.loadFooters(language);
    // Note: `loadOneTrust()` MUST be called after setting the document lang attribute.
    // Because the One Trust popup is translated according to the `lang` attribute of the `<html>` tag.
    const clientId = getSearchParamFromUrl('client');
    const clientIdsExcludingCookiesBanner = environment?.excludeCookieBanner?.clientIds;
    this.showCookiesButton = clientIdsExcludingCookiesBanner ? !clientIdsExcludingCookiesBanner.includes(clientId) : true;
    this.showCookiesButton && this.trackingService.loadOneTrust();
    this.trackingService.loadGoogleTagManager(this.phraseLanguage);
  }
}
