import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-rc-checkbox',
  templateUrl: './rc-checkbox.component.html',
  styleUrls: ['./rc-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcCheckboxComponent),
      multi: true,
    },
  ],
})
export class RcCheckboxComponent implements ControlValueAccessor {
  @Input() value: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() multiLine: boolean;
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter();

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {
    // empty to override
  };
  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {
    // empty to override
  };

  constructor() {
    // empty to override
  }

  /**)
   * Method that is invoked on an update of a model.
   */
  updateChanges(value?: boolean) {
    const val = value !== undefined ? value : this.value;
    if (!this.disabled) {
      this.writeValue(val);
      this.onChange(val);
      this.onCheck.emit(val);
    }
  }
  /**
   * Writes a new item to the element.
   */
  writeValue(value: boolean): void {
    this.value = value;
  }
  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Should be called when the control receives a disabled attribute
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
