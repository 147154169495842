import { LocaleData } from 'i18n-iso-countries';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocationService } from '../location.service';
import { SPECIAL_COUNTRIES } from './countries.config';
import { mapToCountries } from './countries.mappers';
import { Country } from './countries.types';
import { Languages } from 'src/app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private cache: Record<string, Country[]> = {};

  private readonly BASE_URL = this.locationService.getFullUrl('assets/countries');

  private readonly SPECIAL_BASE_URL = this.locationService.getFullUrl('assets/special-countries');

  private readonly FALLBACK_LOCALE = Languages.enUS;

  constructor(private locationService: LocationService, private httpClient: HttpClient) {}

  get(locale: string): Observable<Country[]> {
    if (this.cache[locale]) {
      return of(this.cache[locale]);
    }
    return this.getFromApi(locale).pipe(
      catchError(() => {
        // In case the specified language is not available, fallback to english.
        if (locale !== this.FALLBACK_LOCALE) {
          return this.getFromApi(this.FALLBACK_LOCALE);
        }
        return of({ locale: this.FALLBACK_LOCALE, countries: {} });
      }),
      map(mapToCountries),
      tap((countries) => (this.cache[locale] = countries))
    );
  }

  private getFromApi(locale: string): Observable<LocaleData> {
    return this.httpClient.get<LocaleData>(this.buildCountriesUrl(locale));
  }

  private buildCountriesUrl(locale: string): string {
    if (SPECIAL_COUNTRIES.includes(locale)) {
      // In the special countries folder,
      // the filename is based on the locale like: `zh-TW.json`.
      return `${this.SPECIAL_BASE_URL}/${locale}.json`;
    }
    // We are using the NPM package "i18n-iso-countries".
    // This package provides JSON files like: `en.json`, `fr.json`, ...
    // So, the filename is based only on the language (not the locale).
    const language = locale.split('-')[0];
    return `${this.BASE_URL}/${language}.json`;
  }
}
