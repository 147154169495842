import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnvironmentService } from 'src/app/core/environment.service';
import { TranslateModule } from '@ngx-translate/core';
import { RCFooterModule, RCScrollButtonModule, RCIconModule } from '@rc/ui';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-rc-footer',
  templateUrl: './rc-footer.component.html',
  styleUrls: ['./rc-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RCFooterModule, RCScrollButtonModule, RCIconModule, TranslateModule],
})
export class RcFooterComponent {
  constructor(public env: EnvironmentService) {}
  @Input() version = '';
}
