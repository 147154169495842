import { ReplaySubject, Subscription } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AccessToken, IDToken, OktaAuth } from '@okta/okta-auth-js';

import { AuthTokens } from './auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticated: boolean | undefined;
  private isAuthenticated$$ = new ReplaySubject<boolean>(1);
  isAuthenticated$ = this.isAuthenticated$$.asObservable();

  private subscription!: Subscription;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private authStateService: OktaAuthStateService,
  ) {
    this.observeIsAuthenticated();
    this.initIsAuthenticated();
  }

  async implicitCallback(): Promise<Error | void> {
    try {
      // Handles the response from Okta, parses tokens and redirect.
      await this.oktaAuth.handleLoginRedirect();
    } catch (err) {
      return err;
    }
  }

  loginRedirect(fromUri?: string, idp?: string): Promise<void> {
    return this.oktaAuth.signInWithRedirect({
      originalUri: fromUri || [''].join('/'),
      idp,
    });
  }

  logout(): Promise<boolean> {
    return this.oktaAuth.signOut({ postLogoutRedirectUri: [''].join('/') });
  }

  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  async getTokens(): Promise<AuthTokens> {
    const tokenManager = this.oktaAuth.tokenManager;
    return Promise.all([tokenManager.get('accessToken'), tokenManager.get('idToken')]).then(([accessTkn, idTkn]) => {
      const accessToken = accessTkn as AccessToken | undefined;
      const idToken = idTkn as IDToken | undefined;
      const extras: AuthTokens['extras'] = {};
      const authTokens: AuthTokens = { accessToken, idToken, extras };
      if (accessToken) {
        extras.authorizationHeader = { Authorization: `Bearer ${accessToken.accessToken}` };
      }
      if (idToken) {
        extras.userId = idToken.claims.sub;
      }
      return authTokens;
    });
  }

  private observeIsAuthenticated(): void {
    this.subscription = this.authStateService.authState$.subscribe(({ isAuthenticated }) => {
      this.updateIsAuthenticated(isAuthenticated);
    });
  }

  private async initIsAuthenticated(): Promise<void> {
    const isAuthenticated = await this.oktaAuth.isAuthenticated();
    this.updateIsAuthenticated(isAuthenticated);
  }

  private updateIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated = isAuthenticated;
    this.isAuthenticated$$.next(this.isAuthenticated);
  }
}
