import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/shared/providers/window.provider';
import { asyncScheduler } from 'rxjs';
import { DomService } from 'src/app/core/services/dom/dom.service';
import { availableLanguages, getCurrentPhraseLanguage, Languages } from 'src/app/shared/utils/localization';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private domService: DomService, public env: EnvironmentService, @Inject(WINDOW) private window: Window) {}

  loadFooters(language: string): void {
    const phraseLanguage = getCurrentPhraseLanguage(language);
    if (this.env.isRoyalCanin) {
      const scriptSrc = availableLanguages.find((x) => x.name === phraseLanguage)?.footerUrl;
      this.domService.loadScript({ src: scriptSrc });

      if (language === Languages.frFR) {
        asyncScheduler.schedule(() => (this.window.document.getElementById('rc-footer').style.display = 'block'));
      }
    }
    if (this.env.isWhiteLabel) {
      const whiteLabelFooter = this.env.getWhileLabelFooter();
      if (whiteLabelFooter) {
        this.domService.loadScript({ src: whiteLabelFooter });
      }
    }
  }
}
