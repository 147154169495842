export class Constants {
  /* Pagination */
  static readonly LIMIT_DIRECTORY_COUNT = 30;
  static readonly MORE_DIRECTORY_COUNT = 20;
  static readonly LIMIT_AUTOCOMPLETE = 5;
  static readonly LIMIT_CONSULTATIONS_PAGE = 5;

  /* Truncate String */
  static readonly LIMIT_STRING = 30;

  static readonly COUNTRIES_SHOW_MIDDLE_NAME = ['NL'];
}
