import { ActivatedRouteSnapshot } from '@angular/router';
import { RegisterService } from '@app/core/services';
import { getDomainFromUrl, getSearchParamFromUrl } from '@app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegisterDataResolverModel } from '../models/register-data-resolver.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegisterDataResolver {
  constructor(private registerService: RegisterService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RegisterDataResolverModel> {
    const params = route.queryParams;
    const redirect_uri = params.redirect_uri;
    const callback: string = redirect_uri;
    const clientId: string = getSearchParamFromUrl('client');
    const origin: string = params.origin || getDomainFromUrl(redirect_uri);
    const loginRedirect = this.buildLoginRedirectUrl(params.login_redirect_uri, origin);

    return this.registerService.verifyRedirectUri(loginRedirect).pipe(
      map((isRedirectLoginWhiteListed) => {
        return {
          callback,
          clientId,
          origin,
          loginRedirect: isRedirectLoginWhiteListed ? loginRedirect : '#',
          redirectWhitelisted: isRedirectLoginWhiteListed,
        };
      }),
    );
  }

  private buildLoginRedirectUrl(login_redirect_uri: string, origin: string): string {
    if (login_redirect_uri) {
      return login_redirect_uri;
    }
    return `${origin}/implicit/login`;
  }
}
