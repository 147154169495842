<div class="wrapper" *ngIf="isAvailable">
  <div class="buttons-social">
    <button
      class="button buttons-social__facebook"
      appTracking="registration.socialLoginFacebookClick"
      (click)="loginWithSocial('facebook')"
    >
      {{ 'socialLogin_buttonFacebook' | translate }}
    </button>
    <button class="button buttons-social__google" appTracking="registration.socialLoginGoogleClick" (click)="loginWithSocial('google')">
      {{ 'socialLogin_buttonGoogle' | translate }}
    </button>
    <button class="button buttons-social__apple" appTracking="registration.socialLoginAppleClick" (click)="loginWithSocial('apple')">
      {{ 'socialLogin_buttonApple' | translate }}
    </button>
    <button class="button buttons-social__paypal" appTracking="registration.socialLoginPaypalClick" (click)="loginWithSocial('paypal')">
      {{ 'socialLogin_buttonPaypal' | translate }}
    </button>
  </div>

  <p class="info">
    {{ 'socialLogin_implicitConsent1_youAgreeToOur' | translate }}
    <a [href]="openTermsHref$ | async" target="_blank" rel="noopener" class="rc-link">{{
      'socialLogin_implicitConsent2_marsPrivacyPolicy' | translate
    }}</a
    >.
    {{ 'socialLogin_implicitConsent3_personalizedExprerience' | translate }}
  </p>

  <div class="separator">
    <span>{{ 'registerApp_or' | translate }}</span>
  </div>
</div>
