import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EnvironmentService } from 'src/app/core/environment.service';
import { buildMailtoHref, ContactSupportInfos, getContactSupportEmails } from '@app/core/config/contact-support';
import { RootErrorPageInfos, ErrorContext, STORAGE_KEY_ERROR, ErrorTextsDisplayed } from '@app/core/models';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@app/core/services/storage/storage.service';
import { addSearchToUrl } from '@app/shared/utils';
import { tap } from 'rxjs/operators';
import { RegisterService } from '@app/core/services';
import { WINDOW } from '@app/shared/providers/window.provider';
import { NgClass, NgIf } from '@angular/common';
import { ContactUsButtonComponent } from '@app/shared/components/contact-us/contact-us-button/contact-us-button.component';

@Component({
  selector: 'app-root-error-page',
  templateUrl: './root-error-page.component.html',
  styleUrls: ['./root-error-page.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule, ContactUsButtonComponent],
})
export class RootErrorPageComponent implements OnInit {
  public infos: RootErrorPageInfos = {
    context: ErrorContext.REGISTER,
    title: this.translateService.instant('registerApp_errorPageTitle'),
    summary: this.translateService.instant('registerApp_errorPageRegisterSummary'),
    details: '',
  };
  public contactSupportLink = '';
  public redirectUri = '';
  public displayedText: ErrorTextsDisplayed = this.env.getDisplayedTextInErrorPageFromEnv();

  private ciamSupportEmails: string[];

  constructor(
    private translateService: TranslateService,
    private contactSupportService: ContactSupportService,
    private activeRoute: ActivatedRoute,
    private storageService: StorageService,
    private registerService: RegisterService,
    public env: EnvironmentService,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit(): void {
    // redirectUri will be soon retrieved from session storage
    const clientId = this.activeRoute.snapshot.queryParamMap.get('client');
    this.redirectUri = this.activeRoute.snapshot.queryParamMap.get('redirect_uri');
    this.ciamSupportEmails = getContactSupportEmails();
    const errorInfos = this.storageService.getSession<ContactSupportInfos>(STORAGE_KEY_ERROR);
    this.contactSupportLink = this.env.getUrlContactSupport(clientId) || this.buildContactSupportLink(errorInfos);
    this.infos = {
      context: errorInfos?.context || ErrorContext.REGISTER,
      title: errorInfos?.title || this.translateService.instant('registerApp_errorPageTitle'),
      summary: errorInfos?.summary || this.translateService.instant('registerApp_errorPageRegisterSummary'),
      details: errorInfos?.details || '',
    };
    this.displayedText = this.env.getDisplayedTextInErrorPageFromEnv(clientId);
  }

  goBack(): void {
    this.registerService
      .verifyRedirectUri(this.redirectUri)
      .pipe(
        tap((isValid) => {
          this.window.location.href = isValid ? addSearchToUrl(this.redirectUri, 'origin', 'cancel') : this.env.getGlobalRedirectURI();
        }),
      )
      .subscribe();
  }

  private buildContactSupportLink(infos: ContactSupportInfos): string {
    const { subject, body } = this.contactSupportService.buildEmail(infos);
    return buildMailtoHref(this.ciamSupportEmails, subject, body);
  }
}
