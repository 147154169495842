import { ScriptAttributes } from '../../dom/dom.types';

export const getOneTrustConfig = (production: boolean): ScriptAttributes => {
  const dataDomainScript = '0aa136da-4417-439d-8faa-632fda4904f1' + (production ? '' : '-test');
  return {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    'data-document-language': 'true',
    'data-domain-script': dataDomainScript,
  };
};
