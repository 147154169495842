export enum ContactUsFormFieldEnum {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'emailAddress',
  PLATFORM_NAME = 'platformName',
  COUNTRY = 'country',
  ISSUE_TYPE = 'typeIssue',
  MESSAGE = 'message',
}
export enum ContactUsFormIssueTypeEnum {
  LOGIN_ACCOUNT = 'Login Account',
  REGISTER_ACCOUNT = 'Register Account',
  RECEIVE_ACTIVATION_MAIL = 'Receive Activation Mail',
  RESET_PASSWORD = 'Reset Password',
  OTHER = 'Other',
}

export enum ContactUsPlatformEnum {
  RCA = 'RCA',
  RS = 'RS',
  VS = 'VS',
  SR = 'SR',
  MF = 'MF',
  MEE = 'MEE',
  FS = 'FS',
  L4P = 'L4P',
  LD = 'LD',
}
