<div class="register-success-container">
  <ng-container *ngIf="codeVerified; else codeVerificationForm">
    <app-loader [relativePosition]="true" [noMobileDisplay]="true"></app-loader>
    <div class="register-success-container__text-container">
      <h1 class="register-success-container__text-container__h1">
        {{ 'registerAppPhoneValidation_loaderValidationTitle' | translate }}
      </h1>

      <p class="register-success-container__text-container__p">
        {{ 'registerAppPhoneValidation_loaderValidationText' | translate }}
      </p>

      <p class="register-success-container__text-container__p">
        <a (click)="goToLogin()" appTracking="registration.loginClick" class="rc-link hyperlink">{{
          'registerAppAccountCreation2' | translate
        }}</a>
      </p>
    </div>
  </ng-container>
  <ng-template #codeVerificationForm>
    <div class="register-success-container__logo" [class.register-success-container__logo--whitelabel]="env.isWhiteLabel">
      <img *ngIf="env.isWhiteLabel" src="./assets/logo.svg" alt="" />
      <img *ngIf="env.isRoyalCanin" src="https://dl.royalcanin.com/8-12-1/logo--primary.svg" alt="" />
    </div>

    <div class="register-success-container__img-bloc">
      <img src="./assets/images/register_success.jpg" alt="" />
    </div>

    <div class="register-success-container__text-container">
      <h1 class="register-success-container__text-container__h1">
        {{ 'registerAppPhoneValidation_Title' | translate }}
      </h1>

      <p class="register-success-container__text-container__p">
        {{ 'registerAppPhoneValidation_introductionText' | translate }}
        <span class="bold-text" *ngIf="user && user.profile">{{ user.profile.primaryPhone }}</span>
      </p>
      <form [formGroup]="registerSuccessForm" (submit)="onSubmit()" class="rc-content-v-middle">
        <div class="rc-dialog-form__input-primaryPhone">
          <rc-field
            [autocomplete]="'disabled'"
            [placeholder]="'registerAppPhoneValidation_OTCCodeField' | translate"
            maxlength="50"
            formControlName="code"
            type="number"
            id="registerSuccessFieldCode"
            [error]="error"
          ></rc-field>
          <rc-button
            [name]="'registerAppPhoneValidation_ConfirmButton' | translate"
            type="submit"
            [disabled]="registerSuccessForm.invalid || submitted"
          ></rc-button>
        </div>
      </form>

      <p class="register-success-container__text-container__p">
        {{ 'registerAppPhoneValidation_SendNewCodeText' | translate }}
      </p>

      <div class="register-success-container__link-container">
        <a
          class="rc-link"
          rel="noreferrer"
          id="registerPrimaryPhoneSendNewCode"
          appTracking="registration.sendVerificationCode"
          (click)="sendVerificationCode()"
        >
          {{ 'registerAppPhoneValidation_SendNewCodeButon' | translate }}
        </a>
        <app-contact-us-button labelKey="contactSupportPage_title" appTracking="registration.contactSupport"></app-contact-us-button>
      </div>
    </div>
  </ng-template>
</div>
