import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleService {
  private language: string;
  private languageCode: string;
  private countryCode: string;

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
  ) {}

  public translateInstant(key: string): string {
    return this.translateService.instant(key);
  }

  public translateGet(key: string): Observable<string>;
  public translateGet(key: string[]): Observable<Record<string, string>>;
  public translateGet(key: string | string[]): Observable<string | Record<string, string>> {
    return this.translateService.get(key);
  }

  public setTitle(key: string): void {
    this.titleService.setTitle(this.translateInstant(key));
  }

  getLanguage(): string {
    return this.language;
  }

  getLanguageCode(): string {
    return this.languageCode;
  }

  // Note: it might be undefined!
  getCountry(): string | undefined {
    return this.countryCode;
  }

  setLanguage(locale: string): void {
    this.language = locale;

    const parts = locale.split('-');
    this.languageCode = parts[0].toLowerCase();
    this.countryCode = (parts[1] || '').toUpperCase();
  }
}
