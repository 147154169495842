<div class="contact-us-form__close">
  <img src="./assets/icons/close16.svg" (click)="closeDialog()" class="contact-us-form__close__icon" alt="" role="presentation" />
</div>

<div class="contact-us-form__content">
  <div class="contact-us-form__content__text">
    <h1 class="contact-us-form__content__text__title">{{ language.CONTACT_SUPPORT_FORM_TITLE | translate }}</h1>
    <p>{{ language.CONTACT_SUPPORT_FORM_DESCRIPTION | translate }}</p>
    <p>{{ language.CONTACT_SUPPORT_FORM_INDICATION | translate }}</p>
  </div>
  <div class="contact-us-form__content__image__container">
    <img src="./assets/images/dog-and-cat.png" class="contact-us-form__content__image__container__image" alt="" aria-hidden="true" />
  </div>
  <form [formGroup]="contactForm" (submit)="onSubmit()" class="contact-us-form__content__form" id="contact-us-form">
    <div class="contact-us-form__content__form__form-field half-width">
      <rc-field
        [id]="contactUsFormFieldEnum.FIRSTNAME"
        [formControlName]="contactUsFormFieldEnum.FIRSTNAME"
        [type]="'text'"
        [label]="language.CONTACT_SUPPORT_FORM_FIRST_NAME | translate"
        [required]="true"
        [maxlength]="50"
        [error]="getErrorMessage(contactUsFormFieldEnum.FIRSTNAME) | translate"
        class="half-width"
      ></rc-field>
    </div>
    <div class="contact-us-form__content__form__form-field half-width">
      <rc-field
        [id]="contactUsFormFieldEnum.LASTNAME"
        [formControlName]="contactUsFormFieldEnum.LASTNAME"
        [type]="'text'"
        [label]="language.CONTACT_SUPPORT_FORM_LAST_NAME | translate"
        [required]="true"
        [maxlength]="50"
        [error]="getErrorMessage(contactUsFormFieldEnum.LASTNAME) | translate"
        class="half-width"
      ></rc-field>
    </div>
    <div class="contact-us-form__content__form__form-field">
      <rc-field
        [id]="contactUsFormFieldEnum.EMAIL"
        [formControlName]="contactUsFormFieldEnum.EMAIL"
        [type]="'email'"
        [label]="language.CONTACT_SUPPORT_FORM_EMAIL | translate"
        [required]="true"
        [error]="getErrorMessage(contactUsFormFieldEnum.EMAIL) | translate"
        class="full-width"
      ></rc-field>
    </div>
    <div class="contact-us-form__content__form__form-field">
      <rc-select
        [id]="contactUsFormFieldEnum.COUNTRY"
        [formControlName]="contactUsFormFieldEnum.COUNTRY"
        [label]="language.CONTACT_SUPPORT_FORM_COUNTRY | translate"
        [required]="true"
        [error]="getErrorMessage(contactUsFormFieldEnum.COUNTRY) | translate"
        [items]="countryList"
        class="full-width"
      ></rc-select>
    </div>
    <div class="contact-us-form__content__form__form-field">
      <rc-select
        [id]="contactUsFormFieldEnum.PLATFORM_NAME"
        [formControlName]="contactUsFormFieldEnum.PLATFORM_NAME"
        [label]="language.CONTACT_SUPPORT_FORM_PLATFORM_NAME | translate"
        [required]="true"
        [error]="getErrorMessage(contactUsFormFieldEnum.PLATFORM_NAME) | translate"
        [items]="platformsList"
        class="full-width"
      ></rc-select>
    </div>
    <div class="contact-us-form__content__form__form-field">
      <rc-select
        [id]="contactUsFormFieldEnum.ISSUE_TYPE"
        [formControlName]="contactUsFormFieldEnum.ISSUE_TYPE"
        [label]="language.CONTACT_SUPPORT_FORM_TYPE_ISSUES | translate"
        [required]="true"
        [error]="getErrorMessage(contactUsFormFieldEnum.ISSUE_TYPE) | translate"
        [items]="issueTypeList"
        [customSort]="issueTypesSort"
        class="full-width"
      ></rc-select>
    </div>
    <div class="contact-us-form__content__form__textarea-container">
      <rc-textarea
        [id]="contactUsFormFieldEnum.MESSAGE"
        [required]="isMessageRequired"
        [formControlName]="contactUsFormFieldEnum.MESSAGE"
        [label]="language.CONTACT_SUPPORT_FORM_COMMENT | translate"
        [maxlength]="'800'"
        class="full-width"
        [ngClass]="{ 'contact-us-form__content__form--message-required': isMessageRequired }"
      ></rc-textarea>
      <div *ngIf="contactForm.get(contactUsFormFieldEnum.MESSAGE)?.errors?.required" class="rc-field__error">
        {{ language.ERROR_EMPTY_MESSAGE | translate }}
      </div>
    </div>
    <p><span class="contact-us-form__content__form__mandatory-indicator">*</span> {{ language.MANDATORY_INDICATION | translate }}</p>

    <div class="contact-us-form__content__form__button">
      <a (click)="closeDialog()" class="rc-link">{{ language.CONTACT_SUPPORT_FORM_CANCEL_BUTTON | translate }}</a>
      <p>{{ language.TEXT_OR | translate }}</p>
      <rc-button
        [disabled]="!contactForm.valid"
        id="contactUsSubmitButton"
        type="submit"
        [name]="language.CONTACT_SUPPORT_FORM_SEND_BUTTON | translate"
      ></rc-button>
    </div>
  </form>
</div>
