import { ReplaySubject, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { AccessToken, IDToken } from '@okta/okta-angular/src/okta/models/token-manager';

import { AuthTokens } from './auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticated!: boolean;
  private isAuthenticated$$ = new ReplaySubject<boolean>(1);
  isAuthenticated$ = this.isAuthenticated$$.asObservable();

  private subscription!: Subscription;

  constructor(private oktaAuthService: OktaAuthService) {
    this.observeIsAuthenticated();
    this.initIsAuthenticated();
  }

  async implicitCallback(): Promise<Error | void> {
    try {
      // Handles the response from Okta and parses tokens.
      await this.oktaAuthService.handleAuthentication();

      // Navigate back to the saved uri, or root of application.
      const fromUri = this.oktaAuthService.getFromUri();
      window.location.replace(fromUri);
    } catch (err) {
      return err;
    }
  }

  loginRedirect(fromUri?: string, idp?: string): Promise<void> {
    return this.oktaAuthService.loginRedirect(fromUri || [''].join('/'), { idp });
  }

  logout(): Promise<void> {
    return this.oktaAuthService.logout([''].join('/'));
  }

  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  async getTokens(): Promise<AuthTokens> {
    const tokenManager = this.oktaAuthService.getTokenManager();
    return Promise.all([tokenManager.get('accessToken'), tokenManager.get('idToken')]).then(([accessTkn, idTkn]) => {
      const accessToken = accessTkn as AccessToken | undefined;
      const idToken = idTkn as IDToken | undefined;
      const extras: AuthTokens['extras'] = {};
      const authTokens: AuthTokens = { accessToken, idToken, extras };
      if (accessToken) {
        extras.authorizationHeader = { Authorization: `Bearer ${accessToken.accessToken}` };
      }
      if (idToken) {
        extras.userId = idToken.claims.sub;
      }
      return authTokens;
    });
  }

  private observeIsAuthenticated(): void {
    this.subscription = this.oktaAuthService.$authenticationState.subscribe((isAuthenticated: boolean) => {
      this.updateIsAuthenticated(isAuthenticated);
    });
  }

  private async initIsAuthenticated(): Promise<void> {
    const isAuthenticated = await this.oktaAuthService.isAuthenticated();
    this.updateIsAuthenticated(isAuthenticated);
  }

  private updateIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated = isAuthenticated;
    this.isAuthenticated$$.next(this.isAuthenticated);
  }
}
