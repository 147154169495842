import {
  ContactUsFormFieldEnum,
  ContactUsFormIssueTypeEnum,
  ContactUsPlatformEnum,
} from '@app/shared/components/contact-us/contact-us.enum';

export enum ContactUsTranslateEnum {
  CONTACT_SUPPORT_FORM_TITLE = 'contactSupportForm_title',
  CONTACT_SUPPORT_FORM_DESCRIPTION = 'contactSupportForm_description',
  CONTACT_SUPPORT_FORM_INDICATION = 'contactSupportForm_indication',
  CONTACT_SUPPORT_FORM_FIRST_NAME = 'contactSupportForm_firstName',
  CONTACT_SUPPORT_FORM_LAST_NAME = 'contactSupportForm_lastName',
  CONTACT_SUPPORT_FORM_EMAIL = 'contactSupportForm_email',
  CONTACT_SUPPORT_FORM_PLATFORM_NAME = 'contactSupportForm_platformName',
  CONTACT_SUPPORT_FORM_COUNTRY = 'contactSupportForm_country',
  CONTACT_SUPPORT_FORM_TYPE_ISSUES = 'contactSupportForm_typeIssues',

  TYPE_ISSUES_LOGIN_ACCOUNT = 'typeIssues_loginAccount',
  TYPE_ISSUES_REGISTER_ACCOUNT = 'typeIssues_registerAccount',
  TYPE_ISSUES_RECEIVE_ACTIVATION_MAIL = 'typeIssues_receiveActivationMail',
  TYPE_ISSUES_RESET_PASSWORD = 'typeIssues_resetPassword',
  TYPE_ISSUES_OTHER = 'typeIssues_other',

  CONTACT_SUPPORT_FORM_COMMENT = 'contactSupportForm_comment',
  CONTACT_SUPPORT_FORM_CANCEL_BUTTON = 'contactSupportForm_cancelButton',
  TEXT_OR = 'Text.or',
  CONTACT_SUPPORT_FORM_SEND_BUTTON = 'contactSupportForm_sendButton',
  MANDATORY_INDICATION = 'mandatory_indication',
  SUCCESS_MESSAGE = 'success_message',

  ERROR_INVALID_EMAIL_ADDRESS = 'errorInvalid_emailAddress',
  ERROR_EMPTY_FIRST_NAME = 'errorEmpty_firstName',
  ERROR_EMPTY_LAST_NAME = 'errorEmpty_lastName',
  ERROR_EMPTY_EMAIL_ADDRESS = 'errorEmpty_emailAddress',
  ERROR_EMPTY_PLATFORM_NAME = 'errorEmpty_platformName',
  ERROR_EMPTY_COUNTRY = 'errorEmpty_country',
  ERROR_EMPTY_TYPE_ISSUE = 'errorEmpty_typeIssue',
  ERROR_EMPTY_MESSAGE = 'errorEmpty_message',

  PLATFORM_NAME_RCA = 'platformName_RCA',
  PLATFORM_NAME_RS = 'platformName_RS',
  PLATFORM_NAME_VS = 'platformName_VS',
  PLATFORM_NAME_SR = 'platformName_SR',
  PLATFORM_NAME_MF = 'platformName_MF',
  PLATFORM_NAME_MEE = 'platformName_MEE ',
  PLATFORM_NAME_FGS = 'platformName_FGS',
  PLATFORM_NAME_L_4_P = 'platformName_L4P',
  PLATFORM_NAME_LD = 'platformName_LD',
}

export class RequiredMessageTranslate {
  static [ContactUsFormFieldEnum.FIRSTNAME] = ContactUsTranslateEnum.ERROR_EMPTY_FIRST_NAME;
  static [ContactUsFormFieldEnum.LASTNAME] = ContactUsTranslateEnum.ERROR_EMPTY_LAST_NAME;
  static [ContactUsFormFieldEnum.EMAIL] = ContactUsTranslateEnum.ERROR_EMPTY_EMAIL_ADDRESS;
  static [ContactUsFormFieldEnum.PLATFORM_NAME] = ContactUsTranslateEnum.ERROR_EMPTY_PLATFORM_NAME;
  static [ContactUsFormFieldEnum.COUNTRY] = ContactUsTranslateEnum.ERROR_EMPTY_COUNTRY;
  static [ContactUsFormFieldEnum.ISSUE_TYPE] = ContactUsTranslateEnum.ERROR_EMPTY_TYPE_ISSUE;
  static [ContactUsFormFieldEnum.MESSAGE] = ContactUsTranslateEnum.ERROR_EMPTY_MESSAGE;
}

export class ContactFormIssueTypeTranslate {
  static [ContactUsFormIssueTypeEnum.LOGIN_ACCOUNT] = ContactUsTranslateEnum.TYPE_ISSUES_LOGIN_ACCOUNT;
  static [ContactUsFormIssueTypeEnum.REGISTER_ACCOUNT] = ContactUsTranslateEnum.TYPE_ISSUES_REGISTER_ACCOUNT;
  static [ContactUsFormIssueTypeEnum.RECEIVE_ACTIVATION_MAIL] = ContactUsTranslateEnum.TYPE_ISSUES_RECEIVE_ACTIVATION_MAIL;
  static [ContactUsFormIssueTypeEnum.RESET_PASSWORD] = ContactUsTranslateEnum.TYPE_ISSUES_RESET_PASSWORD;
  static [ContactUsFormIssueTypeEnum.OTHER] = ContactUsTranslateEnum.TYPE_ISSUES_OTHER;
}

export class ContactUsPlatformTranslate {
  static [ContactUsPlatformEnum.RCA] = ContactUsTranslateEnum.PLATFORM_NAME_RCA;
  static [ContactUsPlatformEnum.RS] = ContactUsTranslateEnum.PLATFORM_NAME_RS;
  static [ContactUsPlatformEnum.VS] = ContactUsTranslateEnum.PLATFORM_NAME_VS;
  static [ContactUsPlatformEnum.SR] = ContactUsTranslateEnum.PLATFORM_NAME_SR;
  static [ContactUsPlatformEnum.MF] = ContactUsTranslateEnum.PLATFORM_NAME_MF;
  static [ContactUsPlatformEnum.MEE] = ContactUsTranslateEnum.PLATFORM_NAME_MEE;
  static [ContactUsPlatformEnum.FS] = ContactUsTranslateEnum.PLATFORM_NAME_FGS;
  static [ContactUsPlatformEnum.L4P] = ContactUsTranslateEnum.PLATFORM_NAME_L_4_P;
  static [ContactUsPlatformEnum.LD] = ContactUsTranslateEnum.PLATFORM_NAME_LD;
}
