import { SpecificCountryConsents } from 'src/app/core/models';
import { IConsent } from 'src/app/core/models/consent';

export function getFormattedConsents(consents: SpecificCountryConsents[]): IConsent[] {
  return consents.reduce((acc: IConsent[], currentConsent: SpecificCountryConsents) => {
    const index = acc.findIndex((consent) => {
      return (
        consent.collection_point_guid === currentConsent.consentCollectionPointId &&
        consent.collection_point_version === currentConsent.consentCollectionPointVersion
      );
    });
    if (index !== -1) {
      acc[index].purposesArray.push(currentConsent.consentPurpose);
      return acc;
    } else {
      const newConsent = {
        collection_point_guid: currentConsent.consentCollectionPointId,
        collection_point_version: currentConsent.consentCollectionPointVersion,
        purposesArray: [currentConsent.consentPurpose],
      };
      return [...acc, newConsent];
    }
  }, []);
}
