import { Injectable } from '@angular/core';
import { ContactSupportInfos } from '@app/core/config/contact-support';
import { ErrorContext } from '@app/core/models';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../../environment.service';
import { ContactUsForm } from '@app/core/models/contact-us-form';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CountryWithPlatforms } from '@app/core/services/countries/countries.types';

@Injectable({
  providedIn: 'root',
})
export class ContactSupportService {
  private readonly endpoint = `${environment.myAccountBffUrl}/contactus`;
  private readonly supportCountriesUrl = `${this.endpoint}/countries`;
  constructor(
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
  ) {}

  sendContactUsForm(contactUsForm: ContactUsForm): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}`, contactUsForm);
  }

  getSupportCountries(): Observable<CountryWithPlatforms[]> {
    return this.httpClient.get<CountryWithPlatforms[]>(this.supportCountriesUrl);
  }

  buildEmail(infos: ContactSupportInfos) {
    let { subject, body } = this.buildDefaultEmail(infos);

    const linesfeed = this.lineFeed(3);
    const separator = '--------------------';

    if (this.shouldAddCustomEmail) {
      const customEmail = this.buildCustomEmail(infos);
      subject += ` - ${customEmail.subject}`;
      body += `${linesfeed}${separator}${this.lineFeed(2)}${customEmail.body}`;
    }

    body += linesfeed;

    return { subject, body };
  }

  private lineFeed(num = 1) {
    return new Array(num).fill('\r\n').join('');
  }

  private get shouldAddCustomEmail() {
    return this.translateService.currentLang !== 'en';
  }

  private buildDefaultEmail(infos: ContactSupportInfos) {
    const registerIssue = 'Error during registration process';
    const setPasswordIssue = 'Error during reset password process';
    const issue = infos?.context === ErrorContext.REGISTER ? registerIssue : setPasswordIssue;

    return this.buildAnyEmail(
      {
        subject: `${infos?.redirectUri}: ${issue}`,
        doNotDelete: 'Please do not delete the information displayed on this e-mail',
        webService: 'Web service:',
        encounteredIssue: 'Encountered issue:',
        userEmail: 'E-mail', // Note: do not add trailing `:` after this label
        yourEmail: 'Enter the email address you use to connect to your Royal Canin account (if different from this one):',
        yourMessage: 'Your message (optional):',
      },
      infos,
    );
  }

  private buildCustomEmail(infos: ContactSupportInfos) {
    const registerIssue = this.translateService.instant('contact_support_email_4_register');
    const setPasswordIssue = this.translateService.instant('contact_support_email_4_set_password');
    const subject = infos?.context === ErrorContext.REGISTER ? registerIssue : setPasswordIssue;

    return this.buildAnyEmail(
      {
        subject, // Note: do not add the `callback` in the subject like in `buildDefaultEmail`.
        doNotDelete: this.translateService.instant('contact_support_email_1'),
        webService: this.translateService.instant('contact_support_email_2'),
        encounteredIssue: this.translateService.instant('contact_support_email_3'),
        userEmail: this.translateService.instant('contactSupportPage_fieldEmail'),
        yourEmail: this.translateService.instant(this.environmentService.getTranslationPrefix() + 'contactSupportPage_askEmail'),
        yourMessage: this.translateService.instant('contact_support_email_5'),
      },
      infos,
    );
  }

  private buildAnyEmail(
    { subject, doNotDelete, webService, encounteredIssue, userEmail, yourEmail, yourMessage },
    infos: ContactSupportInfos,
  ) {
    const body = [doNotDelete, this.lineFeed(), `${webService} ${infos?.redirectUri}`, `${encounteredIssue} ${subject}`];
    if (infos?.email) {
      body.push(`${userEmail}: ${infos?.email}`); // Note: add trailing `:` after this label
    } else {
      body.push(...[this.lineFeed(), yourEmail]);
    }
    body.push(...[this.lineFeed(), ...this.additionnalInformation(infos), this.lineFeed(), yourMessage]);
    return { subject, body: body.join(this.lineFeed()) };
  }

  private additionnalInformation(infos: ContactSupportInfos): string[] {
    return [
      `Type : ${infos?.context ?? ''}`,
      `API in error : ${infos?.apiUrlError ?? ''}`,
      `API code error: ${infos?.apiCodeError ?? ''}`,
      `API error return : ${infos?.apiErrorReturn ?? ''}`,
      `Client id : ${infos?.clientId ?? ''}`,
      `redirect uri : ${infos?.redirectUri ?? ''}`,
      `User locale : ${infos?.userLocale ?? ''}`,
    ];
  }
}
