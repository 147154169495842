import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  /**
   * Pattern validator working with regex
   */
  static patternValidator(regex: RegExp, error: ValidationErrors, shouldIncludeCharactersFromRegex: boolean): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = shouldIncludeCharactersFromRegex ? regex.test(control.value) : !regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  /**
   * Validator to compare password and his confirmation
   */
  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('passwordConfirm').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      return { NoPassswordMatch: true };
    }
    return null;
  }

  static noBlankString(control: AbstractControl) {
    const isBlank = (control.value || '').trim().length === 0;
    return isBlank ? { blankString: true } : null;
  }

  static includes(list: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      return !control.value || list.includes(control.value) ? null : { notIncluded: true };
    };
  }
}
