import { ErrorContext, RegisterUser } from '@app/core/models';
import { Component, OnInit } from '@angular/core';
import { buildMailtoHref, getContactLink, getContactSupportEmails, hasContactLink } from 'src/app/core/config/contact-support';
import { EnvironmentService } from 'src/app/core/environment.service';
import { getSearchParamFromUrl } from '@app/shared/utils';
import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { TranslateModule } from '@ngx-translate/core';
import { TrackingDirective } from '@app/core/services/tracking/tracking.directive';
import { NgIf } from '@angular/common';
import { ContactUsButtonComponent } from '@app/shared/components/contact-us/contact-us-button/contact-us-button.component';

@Component({
  selector: 'app-register-page-success',
  templateUrl: './register-page-email-success.component.html',
  styleUrls: ['./register-page-email-success.component.scss'],
  standalone: true,
  imports: [NgIf, TrackingDirective, TranslateModule, ContactUsButtonComponent],
})
export class RegisterPageEmailSuccessComponent implements OnInit {
  user: RegisterUser;
  redirectUri: string;
  mailto: string;
  contactLink: string;
  ciamSupportEmails: string[];

  constructor(
    private contactSupportService: ContactSupportService,
    public env: EnvironmentService,
  ) {}

  ngOnInit(): void {
    /* Here's the expected data structure:
    history.state.data = {
      callback: 'https://account.royalcanin.com',
      locale: 'fr_FR',
      profile: {
        firstName: 'Stéphane',
        lastName: 'Francel',
        email: 'sfrancel@yopmail.com',
        login: 'sfrancel@yopmail.com',
        locale: 'fr_FR',
        preferredLanguage: 'fr-FR',
        countryCode: 'FR'
      },
      credentials: {
        password: {
          value: '2020@Pazword'
        }
      }
    };
    */
    this.redirectUri = history?.state?.redirectUri;

    if (history.state && history.state.data) {
      this.user = history.state.data;

      this.ciamSupportEmails = getContactSupportEmails();
      const { subject, body } = this.contactSupportService.buildEmail({
        context: ErrorContext.REGISTER,
        redirectUri: this.user?.callback,
        email: this.user?.profile?.email,
        userLocale: this.user?.locale,
        clientId: getSearchParamFromUrl('client'),
        apiCodeError: '',
        apiUrlError: '',
        apiErrorReturn: '',
      });
      this.contactLink = getContactLink(this.user?.profile?.countryCode, this.redirectUri?.includes('vetfocus'));
      this.mailto = buildMailtoHref(this.ciamSupportEmails, subject, body);
    } else {
      window.location.href = this.env.getGlobalRedirectURI();
    }
  }

  clickContact(): void {
    if (this.env.getUrlContactSupport()) {
      this.openUrl(this.env.getUrlContactSupport());
    } else if (this.redirectUri?.includes('vetfocus') || hasContactLink(this.user?.profile?.countryCode)) {
      this.openUrl(this.contactLink);
    } else {
      this.openUrl(this.mailto);
    }
  }

  openUrl(urlString: string): void {
    if (urlString) {
      window.location.href = urlString;
    }
  }
}
