import { LoginType } from '@app/shared/utils/enums/login-type.enum';
import { SpecificConsentsCheckboxComponentEnum } from 'src/app/core/models';
import { TrackingEnv } from '../app/core/services/tracking/tracking.env';
import { AppType, IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  ciamAPIUrl: 'https://prd-weu1-rc-df-ciam-api-webservice-dev.cloud-effem.com/lifecycle',
  apimAPIUrl: 'https://dev-eus2-rcapi-apim.azure-api.net/ciam',
  OcpApimSubscriptionKey: '18e2b7217026439d9da33b136021ed55',
  globalRedirectUri: 'https://royalcanin.com',
  languagesCombinations: {
    '0oa11oziwpyW4kKah0h8': 'nl-NL',
    '0oaxwyztoygwxy1cJ0h7': 'ru-RU',
    '0oarqqrm2e3IPnUib0h7': 'ru-RU',
  },
  excludeCookieBanner: {
    clientIds: [
      '0oa11j7azckoPtT1m0h8',
      '0oa15qh7sxh307ltY0h8',
      '0oa16tiorj71mzJKU0h8',
      '0oa16tisk7rgfgrha0h8',
      '0oa1dgk5onk8zTSzH0h8',
      '0oa1dgkk5uzFuKWoT0h8',
      '0oa1m1j2os4eEtg1U0h8',
    ],
  },
  specificLoginTypes: {
    '0oau9fakeClientID': [LoginType.primaryPhone],
    '0oau8fakeClientID': [LoginType.username],
    '0oavyzr7vpPokb7Ne0h7': [LoginType.username],
    '0oa15z7kftfLiCwBE0h8': [LoginType.primaryPhone],
    '0oa15z7lf4zDiWdtg0h8': [LoginType.primaryPhone],
  },
  excludedFromSocials: {
    clientIds: [
      '0oa11j7azckoPtT1m0h8', // app-loc-ib-mi-royal-canin-mobile
      '0oa16tiorj71mzJKU0h8',
      '0oa16tisk7rgfgrha0h8',
      '0oa15z7kftfLiCwBE0h8',
      '0oa15z7lf4zDiWdtg0h8',
      '0oa1dgk5onk8zTSzH0h8',
      '0oa1dgkk5uzFuKWoT0h8',
    ],
    countries: ['AR', 'CN', 'RU', 'TW', 'HK', 'KR', 'TR'],
    languages: [
      'bo',
      'ii',
      'ug',
      'zh', // "CN" / "TW" / "HK"
      'ba',
      'ru',
      'tt', // "RU"
      'ko', // "KR"
      'tr',
    ],
    combinations: [
      //Vet Services CAN
      {
        clientId: '0oaaxam8hqExiGHuG0x6',
        country: 'CA',
      },
    ],
  },
  trackingEnv: TrackingEnv.local,
  okta: {
    domain: 'accountdev.royalcanin.com',
    clientId: '0oau9oiqwseoPDv3X0h7',
    socialIdp: {
      facebook: '0oat2htelxpazCKA70h7',
      google: '0oas0dmwhfcnkpIW80h7',
      paypal: '0oa1dk36l8aOn5Eyt0h8',
      apple: '0oa16v3rc7ooEUsLt0h8',
    },
  },
  appType: AppType.RC,
  isWhiteLabel: false,
  specificCountryCheckboxesConsents: [
    {
      consentCollectionPointId: '0adf3eeb-66ec-42b7-99d1-2bfcf6a6ee5b',
      consentCollectionPointVersion: 13,
      consentPurpose: '3b1b7e10-883e-48e0-a432-ebd3c4a488ab',
      id_component: SpecificConsentsCheckboxComponentEnum.LEGAL_CONSENT,
      displayCheckbox: {
        show: false,
        exceptionCountries: ['CN', 'TW', 'HK', 'ID', 'RU', 'VN', 'MY'],
      },
    },
    {
      countryCode: 'KR',
      consentCollectionPointId: '5e86cb48-d758-4084-8d30-eea598df781e',
      consentCollectionPointVersion: 38,
      consentPurpose: 'b69ce30e-c582-4ac6-93fa-26e3cdfb53ca',
      id_component: SpecificConsentsCheckboxComponentEnum.KR_PRIVACY_COLLECT,
      displayCheckbox: { show: true, exceptionCountries: [] },
    },
    {
      countryCode: 'KR',
      consentCollectionPointId: '5e86cb48-d758-4084-8d30-eea598df781e',
      consentCollectionPointVersion: 38,
      consentPurpose: 'ddce2d5c-ef20-4df4-b78e-8d8a9b804677',
      id_component: SpecificConsentsCheckboxComponentEnum.KR_PRIVACY_TRANSFER,
      displayCheckbox: { show: true, exceptionCountries: [] },
    },
  ],
  contactSupportUrls: {
    '0oavyzr7vpPokb7Ne0h7': 'https://my.rcomsqa.com/contactus/customer-care',
  },
  handleDisplayedTextInErrorPage: {
    DEFAULT: { contactSupport: true, title: true, summary: true, details: true, goBack: true },
    '0oa15ac0j3g03cX480h8': {
      contactSupport: false,
      title: true,
      summary: true,
      details: true,
      goBack: false,
      additionnalText: 'errorPage_japanMessage',
    },
  },
};
