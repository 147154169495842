import { enableProdMode, importProvidersFrom } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { LocaleService } from './app/core/services';
import { ActivatePageModule } from './app/pages/activate-page/activate-page.module';
import { RegisterPageModule } from './app/pages/register-page/register-page.module';
import { SetPasswordPageModule } from './app/pages/set-password-page/set-password-page.module';
import { SharedModule } from './app/shared';
import { MissingTranslationHandlerCustom } from './app/translate/missing-translation.handler';
import { environment } from './environments/environment';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { oktaConfigProvider } from './app/core/auth/auth.provider';

// Required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RouterModule,
      AppRoutingModule,
      ActivatePageModule,
      RegisterPageModule,
      SetPasswordPageModule,
      OktaAuthModule,
      SharedModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationHandlerCustom,
        },
        useDefaultLang: false,
      }),
    ),
    {
      provide: OKTA_CONFIG,
      useFactory: oktaConfigProvider,
    },
    LocaleService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
