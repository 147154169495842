import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import {
  RcAlertModule,
  RCAlertType,
  RCButtonModule,
  RCFieldModule,
  RCIconModule,
  RCSelectItem,
  RCSelectModule,
  RCTextareaModule,
} from '@rc/ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContactFormIssueTypeTranslate,
  ContactUsPlatformTranslate,
  ContactUsTranslateEnum,
  RequiredMessageTranslate,
} from '@app/shared/components/contact-us/contact-us-translate';
import { AsyncPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from '@app/core/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '@app/shared/validators';
import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { ContactUsForm } from '@app/core/models/contact-us-form';
import { ControlsOf } from '@app/shared/utils/form-utils';
import {
  ContactUsFormFieldEnum,
  ContactUsFormIssueTypeEnum,
  ContactUsPlatformEnum,
} from '@app/shared/components/contact-us/contact-us.enum';
import { MessageService } from '@app/core/services/message.service';
import { CountryWithPlatforms } from '@app/core/services/countries/countries.types';

interface ContactUsFormFields {
  [ContactUsFormFieldEnum.FIRSTNAME]: string | null;
  [ContactUsFormFieldEnum.LASTNAME]: string | null;
  [ContactUsFormFieldEnum.EMAIL]: string | null;
  [ContactUsFormFieldEnum.PLATFORM_NAME]: string | null;
  [ContactUsFormFieldEnum.COUNTRY]: string | null;
  [ContactUsFormFieldEnum.ISSUE_TYPE]: string | null;
  [ContactUsFormFieldEnum.MESSAGE]: string | null;
}

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RCIconModule,
    TranslateModule,
    RCFieldModule,
    RCSelectModule,
    AsyncPipe,
    RCTextareaModule,
    RCButtonModule,
    NgOptimizedImage,
    NgClass,
    NgIf,
    RcAlertModule,
  ],
})
export class ContactUsFormComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  private translations: Record<string, string>;
  private countriesWithPlatforms: CountryWithPlatforms[] = [];
  language = ContactUsTranslateEnum;
  countryList = new Array<RCSelectItem>();
  issueTypeList = new Array<RCSelectItem>();
  issueTypesSort = () => 0;
  platformsList = new Array<RCSelectItem>();
  contactForm!: FormGroup<ControlsOf<ContactUsFormFields>>;
  contactUsFormFieldEnum = ContactUsFormFieldEnum;

  get isMessageRequired(): boolean {
    return this.contactForm?.get(ContactUsFormFieldEnum.MESSAGE).hasValidator(Validators.required);
  }

  constructor(
    private dialogRef: DialogRef<string>,
    private localeService: LocaleService,
    private formBuilder: FormBuilder,
    private contactSupportService: ContactSupportService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.initLanguage();
    this.getCountriesWithPlatforms();
    this.initForm();
    this.subscribeFormChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.contactSupportService.sendContactUsForm(this.contactForm.value as ContactUsForm).subscribe({
        next: () => {
          this.closeDialog();
          this.messageService.add({
            id: 'success_message',
            keyTrad: 'success_message',
            type: RCAlertType.SUCCESS,
          });
        },
        error: () => {
          this.messageService.add({
            id: 'error_message',
            keyTrad: 'contactSupport_submission_failed',
            type: RCAlertType.ERROR,
          });
        },
      });
    }
  }

  getErrorMessage(formControlName: ContactUsFormFieldEnum): string {
    const control = this.contactForm.get(formControlName);
    const errors = control?.errors;
    const firstKeyError = (errors && Object.keys(errors).shift()) || undefined;
    if (firstKeyError) {
      switch (firstKeyError) {
        case 'required':
          return control.touched ? RequiredMessageTranslate[formControlName] : '';
        case 'email':
          return control.touched ? ContactUsTranslateEnum.ERROR_INVALID_EMAIL_ADDRESS : '';
      }
    }
    return '';
  }

  private initLanguage() {
    this.localeService
      .translateGet(Object.values(ContactUsTranslateEnum))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translations: Record<string, string>) => {
        if (translations) {
          this.translations = translations;
          this.buildIssueTypeList();
        }
      });
  }

  private getCountriesWithPlatforms() {
    return this.contactSupportService.getSupportCountries().subscribe({
      next: (countriesWithPlatforms) => {
        this.countriesWithPlatforms = countriesWithPlatforms;
        this.buildCountryList();
      },
      error: () => {
        this.messageService.add({
          id: 'error_listCountry_failed',
          keyTrad: 'error_listCountry_failed',
          type: RCAlertType.ERROR,
        });
      },
    });
  }

  private buildIssueTypeList() {
    this.issueTypeList = Object.keys(ContactUsFormIssueTypeEnum).map((key: string) => ({
      label: this.translations[ContactFormIssueTypeTranslate[ContactUsFormIssueTypeEnum[key]]],
      value: ContactUsFormIssueTypeEnum[key],
    }));
  }

  private buildPlatformList(platforms: ContactUsPlatformEnum[]) {
    this.platformsList = platforms.map((key: string) => ({
      label: this.translations[ContactUsPlatformTranslate[key]],
      value: key,
    }));
  }

  private buildCountryList() {
    this.countryList = this.countriesWithPlatforms.map((val) => ({ label: val.label, value: val.code }));
  }

  private initForm() {
    this.contactForm = this.formBuilder.group<ControlsOf<ContactUsFormFields>>({
      [ContactUsFormFieldEnum.FIRSTNAME]: this.formBuilder.control('', [Validators.required, Validators.maxLength(50)]),
      [ContactUsFormFieldEnum.LASTNAME]: this.formBuilder.control('', [Validators.required, Validators.maxLength(50)]),
      [ContactUsFormFieldEnum.EMAIL]: this.formBuilder.control('', [Validators.required, emailValidator()]),
      [ContactUsFormFieldEnum.PLATFORM_NAME]: this.formBuilder.control({ value: '', disabled: true }, Validators.required),
      [ContactUsFormFieldEnum.COUNTRY]: this.formBuilder.control('', Validators.required),
      [ContactUsFormFieldEnum.ISSUE_TYPE]: this.formBuilder.control('', Validators.required),
      [ContactUsFormFieldEnum.MESSAGE]: this.formBuilder.control('', Validators.maxLength(800)),
    });
  }

  private subscribeFormChanges() {
    this.contactForm
      .get(ContactUsFormFieldEnum.ISSUE_TYPE)
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        if (value === ContactUsFormIssueTypeEnum.OTHER) {
          this.contactForm.get(ContactUsFormFieldEnum.MESSAGE).addValidators(Validators.required);
        } else {
          this.contactForm.get(ContactUsFormFieldEnum.MESSAGE).removeValidators(Validators.required);
        }
        this.contactForm.updateValueAndValidity();
      });
    this.contactForm
      .get(ContactUsFormFieldEnum.COUNTRY)
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        const platforms = this.countriesWithPlatforms.find(
          (countryWithPlatforms: CountryWithPlatforms) => countryWithPlatforms.code === value,
        )?.platforms;
        this.contactForm.get(ContactUsFormFieldEnum.PLATFORM_NAME).reset();
        this.buildPlatformList(platforms);
        this.contactForm.get(ContactUsFormFieldEnum.PLATFORM_NAME).enable();
      });
  }
}
