export interface RegisterUser {
  callback?: string;
  locale?: string;
  profile: Profile;
  credentials: Credentials;
  activation_required?: boolean;
  send_email?: boolean;
}

export interface RegisterUserResponse {
  activation_link?: string;
  id: string;
  sessionToken?: string;
}

export interface ConsentCreationResponse {
  receipt: string;
}

export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone?: string;
  login: string;
  locale: string;
  preferredLanguage: string;
  countryCode: string;
}

export interface Credentials {
  password: Password;
}

export interface Password {
  value: string;
}
