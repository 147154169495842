<app-loader *ngIf="!success"></app-loader>
<div class="activate-success__main-ctnr" *ngIf="success">
  <div class="activate-success__main-ctnr__spinner-ctnr">
    <app-loader [relativePosition]="true" [noMobileDisplay]="true"></app-loader>
  </div>

  <div class="activate-success__main-ctnr__text-container">
    <h1 class="activate-success__main-ctnr__text-container__h1">
      {{ 'registerAppSuccess_title' | translate }}
    </h1>

    <p class="activate-success__main-ctnr__text-container__p">
      {{ this.env.getTranslationPrefix() + 'registerAppAccountCreation' | translate }}
    </p>

    <br />

    <p class="activate-success__main-ctnr__text-container__p">
      <a (click)="redirect()" class="rc-link activate-success_link">
        {{ 'registerAppAccountCreation2' | translate }}
      </a>
    </p>
  </div>
</div>
