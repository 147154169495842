<ng-container *ngIf="submitted">
  <div class="rc-loader__full-page">
    <app-loader></app-loader>
  </div>
</ng-container>

<form [formGroup]="setPasswordForm" (submit)="onSubmit(setPasswordForm.value)" class="rc-content-v-middle set-password-form-container">
  <!-- Password -->
  <div class="rc-dialog-form__input set-password-form-container__input" [class.mobile-auto-height]="showPopover">
    <rc-field
      formControlName="password"
      [type]="showPassword ? 'text' : 'password'"
      name="password"
      [placeholder]="'setPassword_fieldPwd' | translate"
      (focus)="popoverOpen()"
      (blur)="popoverClose()"
      [error]="
        passwordControl.touched &&
        !showPopover &&
        (passwordControl.invalid || passwordControl.value === passwordError) &&
        ('registerApp_setPasswordError' | translate)
      "
    >
      <div suffix class="password-eye" (click)="showPassword = !showPassword">
        <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="240" y="96">
          <g data-name="View/Show" fill="#767676">
            <path
              d="M16 21c-4.16 0-7.63-4.19-7.77-4.37a1 1 0 0 1 0-1.26C8.37 15.19 11.84 11 16 11s7.63 4.19 7.77 4.37a1 1 0 0 1 0 1.26C23.63 16.81 20.16 21 16 21zm-5.65-5c1 1 3.28 3 5.65 3s4.47-1.8 5.65-3c-1-1-3.28-3-5.65-3-2.22 0-4.47 1.8-5.65 3z"
            />
            <circle cx="16" cy="16" r="2" />
          </g>
        </svg>
        <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="144" y="128">
          <g data-name="View/Hide" fill="#767676">
            <path
              d="M23.77 15.37a15.35 15.35 0 0 0-3.45-3l1.45-1.74a1 1 0 0 0-1.54-1.28l-1.76 2.11A7.32 7.32 0 0 0 16 11c-4.16 0-7.63 4.19-7.77 4.37a1 1 0 0 0 0 1.26 15.35 15.35 0 0 0 3.45 3l-1.45 1.74a1 1 0 0 0 .13 1.41A1 1 0 0 0 11 23a1 1 0 0 0 .77-.36l1.76-2.11A7.32 7.32 0 0 0 16 21c4.16 0 7.63-4.19 7.77-4.37a1 1 0 0 0 0-1.26zM10.35 16c1.18-1.2 3.43-3 5.65-3a5 5 0 0 1 1.08.14L13 18.06A13.37 13.37 0 0 1 10.35 16zM16 19a5 5 0 0 1-1.08-.14L19 13.94A13.37 13.37 0 0 1 21.65 16c-1.18 1.2-3.43 3-5.65 3z"
            />
            <circle cx="16" cy="16" r="2" />
          </g>
        </svg>
      </div>
    </rc-field>
    <div *ngIf="showPopover" class="rc-input--error-message set-password-form-container__password-errors popover">
      <div class="set-password-form-container__password-rule" translate>SetPassword_rules0</div>
      <div
        class="set-password-form-container__password-rule"
        [class.success__color]="
          !(passwordControl.hasError(validationMessages.password[1].type) || passwordControl.hasError(validationMessages.password[0].type))
        "
      >
        {{ validationMessages.password[1].message }}
      </div>
      <div
        class="set-password-form-container__password-rule"
        [class.success__color]="
          !(passwordControl.hasError(validationMessages.password[2].type) || passwordControl.hasError(validationMessages.password[0].type))
        "
      >
        {{ validationMessages.password[2].message }}
      </div>
      <div
        class="set-password-form-container__password-rule"
        [class.success__color]="
          !(passwordControl.hasError(validationMessages.password[3].type) || passwordControl.hasError(validationMessages.password[0].type))
        "
      >
        {{ validationMessages.password[3].message }}
      </div>
      <div
        class="set-password-form-container__password-rule"
        [class.success__color]="
          !(passwordControl.hasError(validationMessages.password[4].type) || passwordControl.hasError(validationMessages.password[0].type))
        "
      >
        {{ validationMessages.password[4].message }}
      </div>
      <div
        class="set-password-form-container__password-rule"
        [class.success__color]="
          !(passwordControl.hasError(validationMessages.password[5].type) || passwordControl.hasError(validationMessages.password[0].type))
        "
      >
        {{ validationMessages.password[5].message }}
      </div>
      <div class="popover__arrow--scale">
        <div class="popover__arrow"></div>
      </div>
    </div>
  </div>

  <!-- Password Confirm -->
  <div class="rc-dialog-form__input set-password-form-container__input2">
    <rc-field
      formControlName="passwordConfirm"
      [type]="showPasswordConfirm ? 'text' : 'password'"
      name="passwordConfirm"
      [placeholder]="'setPassword_fieldConfirmPwd' | translate"
      [error]="
        passwordConfirmControl.touched &&
        setPasswordForm.hasError(validationMessages.passwordConfirm[1].type) &&
        validationMessages.passwordConfirm[1].message
      "
    >
      <div suffix class="password-eye" (click)="showPasswordConfirm = !showPasswordConfirm">
        <svg *ngIf="!showPasswordConfirm" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="240" y="96">
          <g data-name="View/Show" fill="#767676">
            <path
              d="M16 21c-4.16 0-7.63-4.19-7.77-4.37a1 1 0 0 1 0-1.26C8.37 15.19 11.84 11 16 11s7.63 4.19 7.77 4.37a1 1 0 0 1 0 1.26C23.63 16.81 20.16 21 16 21zm-5.65-5c1 1 3.28 3 5.65 3s4.47-1.8 5.65-3c-1-1-3.28-3-5.65-3-2.22 0-4.47 1.8-5.65 3z"
            />
            <circle cx="16" cy="16" r="2" />
          </g>
        </svg>
        <svg *ngIf="showPasswordConfirm" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="144" y="128">
          <g data-name="View/Hide" fill="#767676">
            <path
              d="M23.77 15.37a15.35 15.35 0 0 0-3.45-3l1.45-1.74a1 1 0 0 0-1.54-1.28l-1.76 2.11A7.32 7.32 0 0 0 16 11c-4.16 0-7.63 4.19-7.77 4.37a1 1 0 0 0 0 1.26 15.35 15.35 0 0 0 3.45 3l-1.45 1.74a1 1 0 0 0 .13 1.41A1 1 0 0 0 11 23a1 1 0 0 0 .77-.36l1.76-2.11A7.32 7.32 0 0 0 16 21c4.16 0 7.63-4.19 7.77-4.37a1 1 0 0 0 0-1.26zM10.35 16c1.18-1.2 3.43-3 5.65-3a5 5 0 0 1 1.08.14L13 18.06A13.37 13.37 0 0 1 10.35 16zM16 19a5 5 0 0 1-1.08-.14L19 13.94A13.37 13.37 0 0 1 21.65 16c-1.18 1.2-3.43 3-5.65 3z"
            />
            <circle cx="16" cy="16" r="2" />
          </g>
        </svg>
      </div>
    </rc-field>
  </div>

  <div class="rc-one-column set-password-form-container__button">
    <rc-button [disabled]="!setPasswordForm.valid" type="submit" [name]="'setPassword_fieldButton' | translate"> </rc-button>
  </div>
</form>
