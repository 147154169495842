import { StorageService } from '@app/core/services/storage/storage.service';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RCAutocompleteItem } from '@rc/ui';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/environment.service';
import {
  Credentials,
  ErrorContext,
  Password,
  Profile,
  RegisterUser,
  RegisterUserResponse,
  SpecificConsentsCheckboxComponentEnum,
  SpecificCountryConsents,
  STORAGE_KEY_ERROR,
} from 'src/app/core/models';
import { LocaleService } from 'src/app/core/services';
import { CountriesService } from 'src/app/core/services/countries/countries.service';
import { MarsPrivacyPolicyService } from 'src/app/core/services/mars-privacy-policy.service';
import { RegisterService } from 'src/app/core/services/register.service';
import { findLanguage } from 'src/app/core/services/tool.helper';
import { TrackingService } from 'src/app/core/services/tracking/tracking.service';
import { getFormattedConsents } from 'src/app/core/services/utils/consents/consents.utils';
import { addSearchToUrl, Languages } from 'src/app/shared/utils';
import { Constants } from 'src/app/shared/utils/constants';
import { LoginType } from 'src/app/shared/utils/enums/login-type.enum';
import { emailValidator, phoneRequired, ValidatorPropName } from 'src/app/shared/validators';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { WINDOW } from '@app/shared/providers/window.provider';
import { TrackingEventType } from '@app/core/services/tracking/config/tracking-map';

@Component({
  selector: 'app-register-page-form',
  templateUrl: './register-page-form.component.html',
  styleUrls: ['./register-page-form.component.scss'],
})
export class RegisterPageFormComponent implements OnInit, OnDestroy {
  @Input() callback: string;
  @Input() origin: string;
  @Input() clientId: string;

  showPassword = false;
  showPasswordConfirm = false;
  showPopover = false;
  registerForm: FormGroup;
  submitted = false;
  countryLabels: RCAutocompleteItem<string>[] = [];
  consents = new FormArray([]);
  consentsSpecificCountry$: BehaviorSubject<SpecificCountryConsents[]> = new BehaviorSubject<SpecificCountryConsents[]>([]);
  loginType: LoginType = LoginType.email;
  isCountryToShowMiddleNameField = Constants.COUNTRIES_SHOW_MIDDLE_NAME.includes(this.localeService.getCountry()) ?? false;

  readonly validatorPropName = ValidatorPropName;
  public readonly LoginType = LoginType;
  // i18n keys for form rules
  private formRulesKeys = [
    'fieldRules_required',
    'fieldRules_same',
    'fieldRules_email',
    'setPassword_rules1',
    'setPassword_rules2',
    'setPassword_rules3',
    'setPassword_rules4',
    'setPassword_rules5',
    'invalid_country_name',
    'FieldError.minLength',
    'FieldError.maxLength',
    'fieldRules_incorrectCharacter',
  ];

  // Validation message in case of error in form
  validationMessages;
  private _destroyed$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private localeService: LocaleService,
    private countriesService: CountriesService,
    private storageService: StorageService,
    private trackingService: TrackingService,
    private marsPrivacyPolicyService: MarsPrivacyPolicyService,
    public env: EnvironmentService,
    @Inject(WINDOW) private window: Window
  ) {
    // Get form rules translation
    this.localeService.translateGet(this.formRulesKeys).subscribe((translations) => {
      if (translations) {
        this.validationMessages = {
          firstName: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'incorrectFormat', message: translations.fieldRules_incorrectCharacter },
            { type: 'minlength', message: translations['FieldError.minLength'] },
            { type: 'maxlength', message: translations['FieldError.maxLength'] },
          ],
          lastName: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'incorrectFormat', message: translations.fieldRules_incorrectCharacter },
          ],
          middleName: [{ type: 'incorrectFormat', message: translations.fieldRules_incorrectCharacter }],
          email: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'email', message: translations.fieldRules_email },
          ],
          countryLabel: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'notIncluded', message: translations.invalid_country_name },
          ],
          password: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'hasNumber', message: translations.setPassword_rules4 },
            { type: 'hasCapitalCase', message: translations.setPassword_rules3 },
            { type: 'hasSmallCase', message: translations.setPassword_rules2 },
            { type: 'hasSpecialCharacters', message: translations.setPassword_rules5 },
            { type: 'minlength', message: translations.setPassword_rules1 },
          ],
          passwordConfirm: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'NoPassswordMatch', message: translations.fieldRules_same },
          ],
        };
      }
    });
  }

  ngOnInit(): void {
    [this.loginType] = this.env.getLoginType();
    this.getCountries();
    this.buildRegisterForm();
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  goBack(): void {
    this.registerService
      .verifyRedirectUri(this.origin)
      .pipe(
        tap((isValid) => {
          this.window.location.href = isValid ? addSearchToUrl(this.origin, 'origin', 'cancel') : this.env.getGlobalRedirectURI();
        })
      )
      .subscribe();
  }

  popoverOpen(): void {
    this.showPopover = true;
  }
  popoverClose(): void {
    this.showPopover = false;
  }
  /**
   * Make name's first letter upperCase and the rest lowerCase
   */
  formatName(name: string): string {
    return name?.trim().charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
  }
  /**
   * Submit actions, get form data and build object to send
   */
  onSubmit(formData): void {
    if (!this.registerForm.valid) return;
    if (this.submitted) {
      return;
    }
    this.submitted = true;

    const { language, locale } = this.findLanguageAndLocale(formData.countryLabel);
    const login = this.loginType === LoginType.email ? formData.email.trim() : `${formData.primaryPhone.phone}@phonenumber.com`;
    // User profile object
    const userProfile: Profile = {
      firstName: this.formatName(formData.firstName),
      middleName: this.formatName(formData?.middleName) || '',
      lastName: this.formatName(formData.lastName),
      email: login,
      login: login,
      locale,
      primaryPhone: formData?.primaryPhone?.phone,
      preferredLanguage: language,
      countryCode: formData.countryLabel,
    };

    // Credential object
    const passwordCredential: Password = {
      value: formData.password,
    };
    const userCredentials: Credentials = {
      password: passwordCredential,
    };
    // Build the final object to send to API
    const registerUser: RegisterUser = {
      callback: this.callback,
      locale,
      profile: userProfile,
      credentials: userCredentials,
    };
    this.sendData(registerUser, formData.consents);
  }

  redirectTo(uri: string): void {
    this.window.location.href = uri;
  }

  /**
   * Find the country and region for the user profile.
   * The country should be the one selected in the registration form.
   * The region should be the one defined in the browser language.
   */
  private findLanguageAndLocale(selectedCountryCode: string) {
    const navigatorLanguage = this.localeService.getLanguage();
    const language = findLanguage(selectedCountryCode, navigatorLanguage.split('-')[0]) || Languages.enUS;
    const locale = language.replace('-', '_');
    return { language, locale };
  }

  // Getters for control
  get firstNameControl() {
    return this.registerForm?.get('firstName');
  }

  get middleNameControl() {
    return this.registerForm.get('middleName');
  }

  get lastNameControl() {
    return this.registerForm?.get('lastName');
  }

  get emailControl() {
    return this.registerForm?.get('email');
  }

  get countryLabelControl() {
    return this.registerForm?.get('countryLabel');
  }

  get passwordControl() {
    return this.registerForm?.get('password');
  }

  get passwordConfirmControl() {
    return this.registerForm?.get('passwordConfirm');
  }

  get controlsAllConsents() {
    return (this.registerForm?.get('consents') as FormArray).controls;
  }
  get primaryPhoneControl() {
    return this.registerForm?.get('primaryPhone');
  }

  /**
   * Build form, with validators
   */
  private buildRegisterForm() {
    const specialCharactersRegex = /[!@#$%^&*()~_¥¨`+§£°€\=\[\]{};:"\\|,.<>\/?0-9]+/;
    this.registerForm = this.formBuilder.group(
      {
        firstName: [
          null,
          [
            Validators.required,
            CustomValidators.noBlankString,
            Validators.maxLength(50),
            Validators.minLength(2),
            CustomValidators.patternValidator(specialCharactersRegex, { incorrectFormat: true }, false),
          ],
        ],
        middleName: [
          null,
          [Validators.maxLength(50), CustomValidators.patternValidator(specialCharactersRegex, { incorrectFormat: true }, false)],
        ],
        lastName: [
          null,
          [
            Validators.required,
            CustomValidators.noBlankString,
            Validators.maxLength(50),
            CustomValidators.patternValidator(specialCharactersRegex, { incorrectFormat: true }, false),
          ],
        ],
        countryLabel: [null, [Validators.compose([Validators.required])]],
        password: [
          null,
          [
            Validators.compose([
              Validators.required,
              // check whether the entered password has a number
              CustomValidators.patternValidator(/\d/, { hasNumber: true }, true),
              // check whether the entered password has upper case letter
              CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }, true),
              // check whether the entered password has a lower-case letter
              CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }, true),
              // check whether the entered password has a special character
              CustomValidators.patternValidator(
                /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                {
                  hasSpecialCharacters: true,
                },
                true
              ),
              // Has a minimum length of 8 characters
              Validators.minLength(8),
            ]),
          ],
        ],
        passwordConfirm: [null, [Validators.compose([Validators.required])]],
        consents: this.consents,
      },
      {
        // check whether our password and confirm password match
        validators: CustomValidators.passwordMatchValidator,
      }
    );
    this.addRequiredLoginControl();
    this.initControlsConsentsInForm();
    this.registerForm.controls.password.valueChanges.pipe(
      takeUntil(this._destroyed$),
      tap(() => this.registerForm.controls.passwordConfirm.updateValueAndValidity())
    );
    this.countryLabelControl.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        map(() => {
          this.marsPrivacyPolicyService.setCountryCode(this.countryLabelControl.value);
          return this.countryLabelControl.value;
        }),
        tap((countryCode) => {
          this.consentsSpecificCountry$.next(this.env.getSpecificConsentsFiltredByCountry(countryCode));
          if (this.loginType === LoginType.primaryPhone) {
            if (!this.primaryPhoneControl.value?.phone) {
              this.primaryPhoneControl.patchValue({
                phone: '',
                countryCode,
              });
              this.primaryPhoneControl.markAsUntouched();
            } else {
              this.primaryPhoneControl.updateValueAndValidity();
            }
          }
        })
      )
      .subscribe();
  }
  /**
   * get the requited loginType
   */
  private addRequiredLoginControl() {
    if (this.loginType === LoginType.primaryPhone) {
      this.registerForm.addControl(
        'primaryPhone',
        new FormControl(
          {
            phone: '',
            countryCode: this.localeService.getCountry(),
          },
          [phoneRequired()]
        )
      );
    } else {
      this.registerForm.addControl('email', new FormControl(null, [emailValidator(), Validators.required]));
    }
  }

  /**
   * Hydrate User object and send it to service, redirect to success page if the register succeed
   */
  private sendData(userToCreate: RegisterUser, consents?: SpecificCountryConsents[]) {
    this.registerService
      .createUser(userToCreate, this.callback, this.clientId)
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((response: RegisterUserResponse) =>
          this.registerService.addConsents(response.id, getFormattedConsents(consents)).pipe(
            tap(() => {
              this.trackingService.pushEvent(
                {
                  [LoginType.email]: 'registration.continueSuccessEmail',
                  [LoginType.primaryPhone]: 'registration.continueSuccessPhone',
                  [LoginType.username]: 'registration.continueSuccessUsername',
                }[this.loginType] as TrackingEventType
              );
              if (response.sessionToken) {
                this.redirectTo(`${this.callback}?origin=register&token=${response.sessionToken}`);
              } else {
                this.router.navigate([this.loginType === LoginType.email ? 'register/email/success' : 'register/primary-phone/success'], {
                  queryParams: { client: this.clientId },
                  state: { data: userToCreate, user_id: response.id },
                });
              }
              return EMPTY;
            })
          )
        ),
        catchError((err) => {
          this.submitted = false;
          this.trackingService.pushEvent('registration.continueError');
          this.storageService.setSession(STORAGE_KEY_ERROR, {
            context: ErrorContext.REGISTER,
            summary: this.localeService.translateInstant('registerApp_errorPageRegisterSummary'),
            details: this.localeService.translateInstant('registerApp_errorPageDetails'),
            clientId: this.clientId,
            apiCodeError: err?.status ?? '',
            apiUrlError: err?.url ?? '',
            apiErrorReturn: err?.error?.message ?? err?.message ?? '',
            redirectUri: this.callback,
            userLocale: userToCreate?.locale ?? '',
          });
          this.router.navigate(['/error'], { queryParams: { client: this.clientId, redirect_uri: this.callback } });
          return EMPTY;
        })
      )
      .subscribe();
  }
  /**
   * Get country list
   */
  private getCountries() {
    this.countriesService.get(this.localeService.getLanguage()).subscribe((countries) => {
      this.countryLabels = countries.map((val) => ({ label: val.label, value: val.code }));
      const countriesCodes = this.countryLabels.map((a) => a.value);
      this.addCountryLabelValidators(countriesCodes);
    });
  }

  private addCountryLabelValidators(countries: string[]) {
    this.registerForm?.controls?.countryLabel?.setValidators([
      Validators.compose([Validators.required, CustomValidators.includes(countries)]),
    ]);
  }

  private createFormGroupConsent(consent: SpecificCountryConsents): FormGroup {
    const { show, exceptionCountries } = consent.displayCheckbox;
    const displayCheckbox = exceptionCountries?.includes(this.countryLabelControl.value) ? !show : show;
    return new FormGroup({
      countryCode: new FormControl(consent.countryCode || null),
      consentCollectionPointId: new FormControl(consent.consentCollectionPointId, Validators.required),
      consentCollectionPointVersion: new FormControl(consent.consentCollectionPointVersion, Validators.required),
      consentPurpose: new FormControl(consent.consentPurpose, Validators.required),
      isChecked: new FormControl(displayCheckbox ? null : true, Validators.requiredTrue),
      id_component: new FormControl(consent.id_component || null),
      text: new FormControl(consent.text || null),
      displayCheckbox: new FormControl(displayCheckbox),
    });
  }

  /**
   * get consents from getSpecificConsentsFiltredByCountry
   * subscribe to each change of list of consents
   * in each change,clear and create/recreate the formArray (FormGroup/FormControl) of consents
   */
  private initControlsConsentsInForm(): void {
    this.consentsSpecificCountry$.next(this.env.getSpecificConsentsFiltredByCountry());
    this.consentsSpecificCountry$.pipe(takeUntil(this._destroyed$)).subscribe((consentsData) => {
      this.consents.clear();
      if (consentsData?.length) {
        consentsData.forEach((consent) => {
          this.consents.push(this.createFormGroupConsent(consent));
        });
      }
    });
  }

  /**
   * Manage input error messages
   * @param control
   * Contains the control of which we will pick up errors
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  getFirstErrorControl(control: AbstractControl | null): string {
    const errors = control?.errors;
    const firstKeyError = (errors && Object.keys(errors).shift()) || undefined;
    if (firstKeyError) {
      switch (firstKeyError) {
        case ValidatorPropName.phoneNumber:
          return 'fieldRules_PhoneNumber';
        case ValidatorPropName.required:
          return control.touched ? 'fieldRules_required' : '';
        case ValidatorPropName.incorrect:
          return 'registerApp_fieldPrimaryPhoneError';
      }
    }
    return '';
  }
}
