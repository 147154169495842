export const KeyTranslateLegalConsent: Record<string, string> = {
  DEFAULT: 'RegisterApp_legalConsentText_global_inEurope',
  CN: 'RegisterApp_legalConsentText_chinese',
  HK: 'RegisterApp_legalConsentText_chinese',
  TW: 'RegisterApp_legalConsentText_chinese',
  CA: 'RegisterApp_legalConsentText_mexique_canada',
  MX: 'RegisterApp_legalConsentText_mexique_canada',
  US: 'RegisterApp_legalConsentText_mexique_canada',
  RU: 'RegisterApp_legalConsentText_russian',
  ID: 'RegisterApp_legalConsentText_Idonesian',
  IL: 'RegisterApp_legalConsentText_global_outOfEurope',
  KR: 'RegisterApp_legalConsentText_global_outOfEurope',
  BR: 'RegisterApp_legalConsentText_global_outOfEurope',
  AR: 'RegisterApp_legalConsentText_global_outOfEurope',
  CO: 'RegisterApp_legalConsentText_global_outOfEurope',
  TR: 'RegisterApp_legalConsentText_global_outOfEurope',
  UA: 'RegisterApp_legalConsentText_global_outOfEurope',
  TH: 'RegisterApp_legalConsentText_global_outOfEurope',
};
