import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getDomainFromUrl } from '@app/shared/utils';
import { LoginType } from '@app/shared/utils/enums/login-type.enum';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../environment.service';
import { ConsentCreationResponse, RegisterUserResponse } from '../models';
import { IConsent } from '../models/consent';
import {
  ActivatePhoneUserRequestBody,
  ActivateUserRequestBody,
  CreateUserRequestBody,
  ResetPasswordByPhoneRequestBody,
  ResetUserPasswordRequestBody,
  SendCodeRequestBody,
  SetUserPasswordRequestBody,
} from '../models/register-service';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  public serviceUrl = environment.ciamAPIUrl;
  public apimUrl = environment.apimAPIUrl;
  public ciamApiUrl = environment.ciamAPIUrl;

  private createUserByEmail = '/lifecycle/register/custom';
  private createUserByPhone = '/lifecycle/phone/register';
  private resetPasswordUByPhone = '/lifecycle/phone/reset';
  private resetPasswordUByUsername = '/lifecycle/username/reset';
  private consentEndpoint = '/consent/users/';
  private activateEndpoint = '/activate';
  private setPwdEndpoint = '/set';
  private verifyRedirectUriEndpoint = '/lifecycle/redirect_uri/verify';
  private resetPwdEndpoint = '/reset';
  private activationPhoneLinkEndPoint = '/lifecycle/phone/activation_link';
  private sendPhoneCode = '/factor/phone/sendpasscode';
  private readonly LoginType = LoginType;
  private loginType: LoginType;

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {
    [this.loginType] = this.environmentService.getLoginType();
  }

  createUser(body: CreateUserRequestBody, redirectUri: string, clientId: string): Observable<RegisterUserResponse> {
    const options = {
      headers: new HttpHeaders({
        redirectUri: redirectUri,
        clientId: clientId || '',
        responseType: 'text',
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };

    return this.httpClient.post<RegisterUserResponse>(
      this.apimUrl + (this.loginType === this.LoginType.primaryPhone ? this.createUserByPhone : this.createUserByEmail),
      body,
      options
    );
  }
  activatePhoneUser(body: ActivatePhoneUserRequestBody): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    return this.httpClient.post(this.apimUrl + this.activationPhoneLinkEndPoint, body, options);
  }

  resetPasswordByPhone(body: ResetPasswordByPhoneRequestBody, loginType: LoginType): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    if (loginType === LoginType.primaryPhone) {
      return this.httpClient.post(this.apimUrl + this.resetPasswordUByPhone, body, options);
    } else {
      return this.httpClient.post(this.apimUrl + this.resetPasswordUByUsername, body, options);
    }
  }

  sendCodePrimaryPhone(body: SendCodeRequestBody): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    return this.httpClient.post(this.apimUrl + this.sendPhoneCode, body, options);
  }

  sendNewPhoneCode(user_id: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    return this.httpClient.post(this.apimUrl + '/factor/users/' + user_id + '/passcode', null, options);
  }

  addConsents(userId: string, consents: IConsent[]) {
    return from(consents).pipe(concatMap((consent) => this.addConsent(userId, consent)));
  }

  addConsent(userId: string, consent: IConsent) {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    return this.httpClient.post<ConsentCreationResponse>(
      this.apimUrl + this.consentEndpoint + userId,
      {
        collection_point_guid: consent.collection_point_guid,
        collection_point_version: consent.collection_point_version,
        purposes: consent.purposesArray.map((purposeId) => ({ Id: purposeId })),
      },
      options
    );
  }

  activateUser(body: ActivateUserRequestBody): Observable<any> {
    return this.httpClient.post(this.endpoint(this.activateEndpoint), body, { responseType: 'text' });
  }

  setUserPassword(body: SetUserPasswordRequestBody): Observable<any> {
    return this.httpClient.post(this.endpoint(this.setPwdEndpoint), body, { responseType: 'text' });
  }

  requestResetPasswordWithUsernameOrEmail(body: ResetUserPasswordRequestBody): Observable<{ loginType: LoginType }> {
    return this.httpClient.post<{ loginType: LoginType }>(this.endpoint(this.resetPwdEndpoint), body);
  }

  verifyRedirectUri(uri: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      }),
    };
    return this.httpClient.post(this.apimUrl + this.verifyRedirectUriEndpoint, { redirect_uri: getDomainFromUrl(uri) }, options).pipe(
      map(() => true),
      catchError(() => {
        console.error('The redirect uri provided is not whitelisted');
        return of(false);
      })
    );
  }

  private endpoint(endpoint: string) {
    return this.serviceUrl + endpoint;
  }
}
