export interface SpecificCountryConsents {
  countryCode?: string; //  if the countryCode is undefined or empty string , the consetement will be displayed for all countries
  consentCollectionPointId: string;
  consentCollectionPointVersion: number;
  consentPurpose: string;
  id_component?: SpecificConsentsCheckboxComponentEnum;
  text?: string;
  isChecked?: boolean;
  displayCheckbox: {
    /*
     ** if show equals true, we display checkbox except for exceptionCountries list
     ** if show equals false, we hide checkbox except for exceptionCountries list
     */
    show: boolean;
    exceptionCountries: string[];
  };
}

export enum SpecificConsentsCheckboxComponentEnum {
  KR_PRIVACY_COLLECT = 'detail-terms-kr-privacy-collect.component',
  KR_PRIVACY_TRANSFER = 'detail-terms-kr-privacy-transfer.component',
  LEGAL_CONSENT = 'legal-consent',
}
