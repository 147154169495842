import { DOCUMENT } from '@angular/common';
import { FactoryProvider } from '@angular/core';
import { OKTA_CONFIG, OktaConfig } from '@okta/okta-angular';

import { environment } from '../../../environments/environment';
import { getIssuerUrl } from './auth.utils';

const issuer = getIssuerUrl();
const { clientId } = environment.okta;

const scopes = ['openid', 'profile', 'email'];

const pkce = true;

export const oktaConfigProvider: FactoryProvider = {
  provide: OKTA_CONFIG,
  useFactory: (document: Document) => {
    const redirectUri = `${document.location.origin}/implicit/callback`;
    return {
      issuer,
      clientId,
      redirectUri,
      scopes,
      pkce,
    } as OktaConfig;
  },
  deps: [DOCUMENT],
};
