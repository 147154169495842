export const termsLinks = [
  {
    countryCode: 'HK',
    path: '/privacy-policy-hong-kong-china',
  },
  {
    countryCode: 'SG',
    path: '/privacy-policy-singapore',
  },
  {
    countryCode: 'MX',
    path: '/privacy-policy-mexico',
  },
  {
    countryCode: 'CO',
    path: '/privacy-policy-colombia',
  },
  {
    countryCode: 'BE',
    path: '/privacy-policy-belgium',
  },
  {
    countryCode: 'CA',
    path: '/privacy-policy-canada',
  },
  {
    countryCode: 'IN',
    path: '/privacy-policy-india',
  },
  {
    countryCode: 'KZ',
    path: '/privacy-policy-kazakhstan',
  },
  {
    countryCode: 'KG',
    path: '/privacy-policy-kyrgyzstan',
  },
  {
    countryCode: 'ZA',
    path: '/privacy-policy-southafrica',
  },
  {
    countryCode: 'TW',
    path: '/privacy-policy-taiwan-china',
  },
  {
    countryCode: 'SA',
    path: '/privacy-policy-saudi-arabia',
  },
  {
    countryCode: 'GB',
    path: '/privacy',
  },
  {
    countryCode: 'IE',
    path: '/privacy',
  },
  {
    countryCode: 'AR',
    path: '/privacy-policy-argentina',
  },
  {
    countryCode: 'AU',
    path: '/privacy-policy-australia',
  },
  {
    countryCode: 'NZ',
    path: '/privacy-policy-australia',
  },
  {
    countryCode: 'AT',
    path: '/privacy-policy-austria',
  },
  {
    countryCode: 'BR',
    path: '/privacy-policy-brazil',
  },
  {
    countryCode: 'BG',
    path: '/privacy-policy-bulgaria',
  },
  {
    countryCode: 'CN',
    path: '/privacy-policy-china',
  },
  {
    countryCode: 'HR',
    path: '/privacy-policy-croatia',
  },
  {
    countryCode: 'CZ',
    path: '/privacy-policy-czechrepublic',
  },
  {
    countryCode: 'DK',
    path: '/privacy-policy-denmark',
  },
  {
    countryCode: 'EE',
    path: '/privacy-policy-estonia',
  },
  {
    countryCode: 'FI',
    path: '/privacy-policy-finland',
  },
  {
    countryCode: 'FR',
    path: '/privacy-policy-france',
  },
  {
    countryCode: 'DE',
    path: '/privacy-policy-germany',
  },
  {
    countryCode: 'GR',
    path: '/privacy-policy-greece',
  },
  {
    countryCode: 'HU',
    path: '/privacy-policy-hungary',
  },
  {
    countryCode: 'IS',
    path: '/privacy-policy-iceland',
  },
  {
    countryCode: 'ID',
    path: '/privacy-policy-indonesia',
  },
  {
    countryCode: 'IT',
    path: '/privacy-policy-italy',
  },
  {
    countryCode: 'JP',
    path: '/privacy-policy-japan',
  },
  {
    countryCode: 'KR',
    path: '/privacy-policy-korea',
  },
  {
    countryCode: 'LV',
    path: '/privacy-policy-latvia',
  },
  {
    countryCode: 'LT',
    path: '/privacy-policy-lithuania',
  },
  {
    countryCode: 'MY',
    path: '/privacy-policy-malaysia',
  },
  {
    countryCode: 'NL',
    path: '/privacy-policy-netherlands',
  },
  {
    countryCode: 'NO',
    path: '/privacy-policy-norway',
  },
  {
    countryCode: 'PL',
    path: '/privacy-policy-poland',
  },
  {
    countryCode: 'PT',
    path: '/privacy-policy-portugal',
  },
  {
    countryCode: 'RO',
    path: '/privacy-policy-romania',
  },
  {
    countryCode: 'RU',
    path: '/privacy-policy-russia',
  },
  {
    countryCode: 'CS',
    path: '/privacy-policy-serbia',
  },
  {
    countryCode: 'SK',
    path: '/privacy-policy-slovakia',
  },
  {
    countryCode: 'SI',
    path: '/privacy-policy-slovenia',
  },
  {
    countryCode: 'ES',
    path: '/privacy-policy-spain',
  },
  {
    countryCode: 'SE',
    path: '/privacy-policy-sweden',
  },
  {
    countryCode: 'CH',
    path: '/privacy-policy-switzerland',
  },
  {
    countryCode: 'TH',
    path: '/privacy-policy-thailand',
  },
  {
    countryCode: 'TR',
    path: '/privacy-policy-turkey',
  },
  {
    countryCode: 'UAE',
    path: '/privacy-policy-uae',
  },
  {
    countryCode: 'UA',
    path: '/privacy-policy-ukraine',
  },
  {
    countryCode: 'US',
    path: '/privacy-policy-us',
  },
];
