import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OktaAuthModule } from '@okta/okta-angular';
import {
  RCAccordionModule,
  RcAlertModule,
  RCAutocompleteModule,
  RCButtonModule,
  RCFieldModule,
  RCFooterModule,
  RCIconModule,
  RCPhoneFieldModule,
  RCScrollButtonModule,
} from '@rc/ui';
import { AuthCallbackComponent } from '../core/auth/auth-callback/auth-callback.component';
import { AuthLoginComponent } from '../core/auth/auth-login/auth-login.component';
import { AuthLogoutComponent } from '../core/auth/auth-logout/auth-logout.component';
import { oktaConfigProvider } from '../core/auth/auth.provider';
import { TrackingDirective } from '../core/services/tracking/tracking.directive';
import { CookieSettingsButtonComponent } from './components/cookie-settings-button/cookie-settings-button.component';
import {
  DetailTermsComponent,
  DetailTermsKRPrivacyCollectComponent,
  DetailTermsKRPrivacyTransferComponent,
} from './components/detail-terms';
import { LoaderComponent } from './components/loader/loader.component';
import { RcCheckboxComponent } from './components/rc-checkbox/rc-checkbox.component';
import { RcFooterComponent } from './components/rc-footer/rc-footer.component';
import { CapitalizeFirstPipe, ConverterHtmlToText, FloorPipe, PublicUrlPipe, RoundPipe, TruncatePipe } from './pipes';
import { windowProvider } from './providers/window.provider';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  RCFieldModule,
  RCFooterModule,
  RcAlertModule,
  RCButtonModule,
  RCPhoneFieldModule,
  RCAutocompleteModule,
  RCIconModule,
  RCScrollButtonModule,
  RCAccordionModule,
];

const pipes = [ConverterHtmlToText, FloorPipe, RoundPipe, PublicUrlPipe, CapitalizeFirstPipe, TruncatePipe];

const directives = [TrackingDirective];

const components = [
  LoaderComponent,
  RcCheckboxComponent,
  CookieSettingsButtonComponent,
  AuthCallbackComponent,
  AuthLoginComponent,
  AuthLogoutComponent,
  RcFooterComponent,
  DetailTermsComponent,
  DetailTermsKRPrivacyTransferComponent,
  DetailTermsKRPrivacyCollectComponent,
];

@NgModule({
  imports: [RouterModule, OverlayModule, ...modules, ...pipes, ...directives, ...components],
  exports: [...modules, ...pipes, ...directives, ...components],
  providers: [TitleCasePipe, RoundPipe, CapitalizeFirstPipe, TruncatePipe, windowProvider],
})
export class SharedModule {}
