import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ContactUsFormComponent } from '@app/shared/components/contact-us/contact-us-form/contact-us-form.component';

@Component({
  selector: 'app-contact-us-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, DialogModule],
  templateUrl: './contact-us-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ContactUsButtonComponent {
  @Input({ required: true }) labelKey: string;
  @Input() styleClass: string = 'rc-link link-translate';

  constructor(public dialog: Dialog) {}

  openContactForm() {
    this.dialog.open<string>(ContactUsFormComponent);
  }
}
