<!-- Main container -->
<div class="register-container">
  <!-- Header container -->
  <div class="register-container__header-container" [class.register-container__header-container--whitelabel]="env.isWhiteLabel">
    <img *ngIf="env.isWhiteLabel" src="./assets/logo.svg" alt="" />
    <img *ngIf="env.isRoyalCanin" src="https://dl.royalcanin.com/8-12-1/logo--primary.svg" alt="" />
  </div>

  <!-- Main content container -->
  <div class="register-content-container">
    <h1 class="register-content-container__title">
      {{ 'registerApp_titleWelcome' | translate }}
      <ng-container *ngIf="env.isRoyalCanin; else wl">
        <span class="red__markup">{{ 'registerApp_titleRC' | translate }}</span>
      </ng-container>
      <ng-template #wl>
        <span class="red__markup">{{ this.env.getTranslationPrefix() + 'registerApp_title' | translate }}</span>
      </ng-template>
    </h1>
    <div class="register-content-container__subtitle-container">
      <span
        >{{ 'registerApp_subtitleSecondLine' | translate }}&nbsp;<a
          (click)="goToLogin()"
          id="registerGoToLogin"
          appTracking="registration.loginClick"
          class="rc-link hyperlink"
          >{{ 'registerApp_login' | translate }}</a
        ></span
      >
    </div>
    <app-register-page-form [callback]="callback" [origin]="origin" [clientId]="clientId"></app-register-page-form>
  </div>
</div>
