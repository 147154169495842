import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterDataResolverModel } from '@app/pages/register-page/models/register-data-resolver.model';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LocaleService } from '../../services';
import { MarsPrivacyPolicyService } from '../../services/mars-privacy-policy.service';
import { AuthService } from '../auth.service';
import { SocialLogin } from '../auth.types';
import { TranslateModule } from '@ngx-translate/core';
import { TrackingDirective } from '../../services/tracking/tracking.directive';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TrackingDirective, AsyncPipe, TranslateModule],
})
export class AuthLoginComponent implements OnInit {
  @Input() clientId: string;

  REDIRECT_URI_PARAM = 'redirect_uri';

  redirectUri: string;
  isRedirectWhitelisted: boolean;
  openTermsHref$ = this.marsPrivacyPolicyService.openTermsHref$;

  isAvailable: boolean;
  isKakaoAvailable: boolean;
  constructor(
    private localeService: LocaleService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private marsPrivacyPolicyService: MarsPrivacyPolicyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.redirectUri = this.route.snapshot.queryParams[this.REDIRECT_URI_PARAM];
    this.isAvailable =
      this.isAvailableForCountry() && this.isAvailableForLanguage() && this.isAvailableForCombination() && this.isAvailableForClientId();
    this.isKakaoAvailable = this.isSocialAvailableForClientID('kakao');
    this.extractRouterData();
  }

  loginWithSocial(social: SocialLogin): void {
    if (this.isRedirectWhitelisted) {
      environment.okta.socialIdp[social] && this.authService.loginRedirect(this.redirectUri, environment.okta.socialIdp[social]);
    } else {
      this.router.navigate(['/error'], {
        queryParams: { [this.REDIRECT_URI_PARAM]: this.redirectUri },
      });
    }
  }

  private isAvailableForClientId() {
    if (environment.excludedFromSocials.clientIds.includes(this.clientId)) {
      return false;
    }
    return true;
  }

  private isAvailableForCountry() {
    if (environment.excludedFromSocials.countries.includes(this.localeService.getCountry())) {
      return false;
    }
    return true;
  }

  private isAvailableForLanguage() {
    if (environment.excludedFromSocials.languages.includes(this.localeService.getLanguageCode())) {
      return false;
    }
    return true;
  }

  private isAvailableForCombination() {
    const isExcludedCombination = environment.excludedFromSocials.combinations.some(
      (combination) => combination.clientId === this.clientId && combination.country === this.localeService.getCountry(),
    );
    return !isExcludedCombination;
  }

  private extractRouterData(): void {
    this.activatedRoute.data
      .pipe(
        map((routeData) => routeData.data),
        tap(({ redirectWhitelisted }: RegisterDataResolverModel) => (this.isRedirectWhitelisted = redirectWhitelisted)),
      )
      .subscribe();
  }

  private isSocialAvailableForClientID(socialNetwork: string): boolean {
    return this.clientId && environment?.includedCustomSocials[socialNetwork]?.clientIds?.includes(this.clientId);
  }
}
