export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  district?: string;
  mobile?: string;
  phone?: string;
  fax?: string;
  region?: string;
  stateAbbrevation?: string;
  zip?: number;
  zipPlus4?: number;
  country?: string;
}
