<ng-container *ngIf="env.isRoyalCanin">
  <div id="rc-footer" class="rc-footer">
    <rc-footer>
      <ng-container top-right>
        <rc-scroll-button anchor="body"> <rc-icon name="up"></rc-icon>{{ 'footer_backtotop' | translate }} </rc-scroll-button>
      </ng-container>
      <ng-container bottom-left>
        <div class="rc-footer__bottom">
          <div class="rc-footer__copyright">{{ 'footer_registerApp' | translate }}{{ ' : ' }}{{ version }}</div>
          <div class="rc-footer__sections">
            <ul>
              <li>
                <a [href]="'https://www.royalcanin.com/fr/mentions-legales/welcome' | translate" target="_blank" rel="noopener noreferrer">
                  {{ 'footer_registerApp_legalnotice' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </rc-footer>
  </div>
</ng-container>
<ng-container *ngIf="env.isWhiteLabelDefault">
  <footer class="footer--placeholder">
    <p>Footer</p>
  </footer>
</ng-container>
