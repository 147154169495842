import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'publicUrl',
  standalone: true,
})
export class PublicUrlPipe implements PipeTransform {
  transform(publicUrl: string, height?: number): any {
    if (!publicUrl || publicUrl === '') {
      return './assets/other/Dry.png';
    }

    return `${publicUrl}?h=${height || 400}&auto=enhance`;
  }
}
