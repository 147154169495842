import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { SetPasswordFailurePageComponent } from './components/set-password-failure-page/set-password-failure-page.component';
import { SetPasswordPageComponent } from './components/set-password-page/set-password-page.component';
import { SetPasswordSuccessPageComponent } from './components/set-password-success-page/set-password-success-page.component';
import { SetPasswordPageContainerComponent } from './container/set-password-page-container/set-password-page-container.component';
import { SetPasswordPageRoutingModule } from './set-password-page-routing.module';

@NgModule({
  imports: [SharedModule, SetPasswordPageRoutingModule],
  declarations: [
    SetPasswordFailurePageComponent,
    SetPasswordPageComponent,
    SetPasswordSuccessPageComponent,
    SetPasswordPageContainerComponent,
  ],
})
export class SetPasswordPageModule {}
