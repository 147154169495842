// https://stackoverflow.com/questions/3191664/list-of-all-locales-and-their-short-codes
const countryLocaleCodes = [
  'af-ZA',
  'am-ET',
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SY',
  'ar-TN',
  'ar-YE',
  'as-IN',
  'be-BY',
  'bg-BG',
  'bn-BD',
  'bn-IN',
  'bo-CN',
  'ca-ES',
  'cs-CZ',
  'cy-GB',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LI',
  'de-LU',
  'dv-MV',
  'el-GR',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et-EE',
  'eu-ES',
  'fa-IR',
  'fi-FI',
  'fo-FO',
  'fr-FR', // Should be the first french locale
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-LU',
  'fr-MC',
  'ga-IE',
  'gd-GB',
  'gl-ES',
  'gu-IN',
  'he-IL',
  'hi-IN',
  'hr-BA',
  'hr-HR',
  'hu-HU',
  'hy-AM',
  'id-ID',
  'ig-NG',
  'ii-CN',
  'is-IS',
  'it-CH',
  'it-IT',
  'ja-JP',
  'ka-GE',
  'kk-KZ',
  'kl-GL',
  'km-KH',
  'kn-IN',
  'ko-KR',
  'ky-KG',
  'lb-LU',
  'lo-LA',
  'lt-LT',
  'lv-LV',
  'mi-NZ',
  'mk-MK',
  'ml-IN',
  'mn-MN',
  'mr-IN',
  'ms-BN',
  'ms-MY',
  'mt-MT',
  'nb-NO',
  'ne-NP',
  'nl-BE',
  'nl-NL',
  'nn-NO',
  'or-IN',
  'pa-IN',
  'pl-PL',
  'ps-AF',
  'pt-BR',
  'pt-PT',
  'rm-CH',
  'ro-RO',
  'ru-RU',
  'ba-RU', // MUST BE PLACED AFTER "ru-RU"
  'rw-RW',
  'sa-IN',
  'se-FI',
  'se-NO',
  'se-SE',
  'si-LK',
  'sk-SK',
  'sl-SI',
  'sq-AL',
  'sv-FI',
  'sv-SE',
  'sw-KE',
  'ta-IN',
  'te-IN',
  'th-TH',
  'tk-TM',
  'tn-ZA',
  'tr-TR',
  'tt-RU',
  'ug-CN',
  'uk-UA',
  'ur-PK',
  'vi-VN',
  'wo-SN',
  'xh-ZA',
  'yo-NG',
  'zh-CN',
  'zh-HK',
  'zh-MO',
  'zh-SG',
  'zh-TW',
  'zu-ZA',
];

const languagesPerCountries: Record<string, string[]> = {};
countryLocaleCodes.forEach((value) => {
  const [, languageCode, countryCode] = value.match(/^([a-z]{2})-([A-Z]{2})$/) || ([] as any);

  if (languageCode && countryCode) {
    languagesPerCountries[countryCode] = languagesPerCountries[countryCode] || [];
    languagesPerCountries[countryCode].push(languageCode);
  }
});

export function findLanguage(countryCode: string, languageCode?: string) {
  if (!languagesPerCountries[countryCode]) {
    return null;
  }

  const languageIndex = languageCode && languagesPerCountries[countryCode].indexOf(languageCode);
  if (languageIndex === -1) {
    const firstLanguageCode = languagesPerCountries[countryCode][0];
    return `${firstLanguageCode}-${countryCode}`;
  }

  return `${languagesPerCountries[countryCode][languageIndex]}-${countryCode}`;
}
