<div class="set-pwd-success-container">
  <div class="set-pwd-success-container__img-bloc">
    <img src="./assets/images/reset_password_success.jpg" alt="" />
  </div>

  <div class="set-pwd-success-container__content">
    <h1 class="set-pwd-success-container__content__h1">{{ 'setPasswordSuccess_title' | translate }}</h1>

    <p class="set-pwd-success-container__content__p">{{ 'setPasswordSuccess_contentFirstLine' | translate }}</p>

    <p class="set-pwd-success-container__content__p-end">
      {{ 'setPasswordSuccess_contentLastLine' | translate }}
      <a class="rc-link set-pwd-success-container__content__p-end__link" appTracking="resetPassword.loginClick" [href]="callback">{{
        'setPasswordSuccess_contentLink' | translate
      }}</a>
    </p>
  </div>
</div>
