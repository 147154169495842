import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-terms-kr-privacy-transfer',
  styleUrls: ['detail-terms-kr-privacy-transfer.component.scss'],
  template: `
    <p>{{ 'tickbox_korea-tickbox2-text' | translate }}</p>
    <ul>
      <li>
        <div class="item">
          <h4>{{ 'tickbox_korea-tickbox2-bullet-1-title' | translate }}</h4>
          <p>
            {{ 'tickbox_korea-tickbox2-bullet-1-text' | translate }}
          </p>
          <a class="rc-button__title rc-button__title--link" href="mailto:privacy@effem.com" target="_blank">(privacy@effem.com)</a>
        </div>
      </li>
      <li>
        <div class="item">
          <h4>{{ 'tickbox_korea-tickbox2-bullet-2-title' | translate }}</h4>
          <p>
            {{ 'tickbox_korea-tickbox2-bullet-2-text' | translate }}
          </p>
        </div>
      </li>
      <li>
        <div class="item">
          <h4>{{ 'tickbox_korea-tickbox2-bullet-3-title' | translate }}</h4>
          <p>
            {{ 'tickbox_korea-tickbox2-bullet-3-text' | translate }}
          </p>
        </div>
      </li>
      <li>
        <div class="item">
          <h4>{{ 'tickbox_korea-tickbox2-bullet-4-title' | translate }}</h4>
          <p>
            {{ 'tickbox_korea-tickbox2-bullet-4-text' | translate }}
          </p>
        </div>
      </li>
      <li>
        <div class="item">
          <h4>{{ 'tickbox_korea-tickbox2-bullet-5-title' | translate }}</h4>
          <p>
            {{ 'tickbox_korea-tickbox2-bullet-5-text' | translate }}
          </p>
        </div>
      </li>
      <li>
        <h4>{{ 'tickbox_korea-tickbox2-bullet-6-title' | translate }}</h4>
        <p class="text-highlight">
          {{ 'tickbox_korea-tickbox2-bullet-6-text-bigger' | translate }}
        </p>
      </li>
    </ul>
  `,
  standalone: true,
  imports: [TranslateModule],
})
export class DetailTermsKRPrivacyTransferComponent {}
