export enum ActionCode {
  wlToWl = 'WLToWL',
  restartWL = 'restartWL',
  wlToWs = 'WLToWS',
  wsToWl = 'WSToWL',
  wsToWs = 'WSToWS',
  wsToWs2 = 'WSToWS2',
  ws2ToWs2 = 'WS2ToWS2',
  ws2ToWl = 'WS2ToWL',
  ws2ToWs = 'WS2ToWS',
  wmToWm = 'WMToWM',
  wmToWl = 'WMToWL',
}
