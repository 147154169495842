export function addSearchToUrl(url: string, key: string, value: string) {
  const urlTarget = new URL(url);
  const params = urlTarget.search ? urlTarget.search.substr(1).split('&') : [];

  const paramsFiltered = params.filter((param) => {
    const paramKey = param.split('=')[0];
    return paramKey && paramKey !== key;
  });

  paramsFiltered.push(`${key}=${value}`);
  urlTarget.search = `?${paramsFiltered.join('&')}`;

  return urlTarget.href;
}

export const getSearchParamFromUrl = (param: string) => {
  try {
    return new URL(document.location.href).searchParams.get(param);
  } catch (e) {
    return null;
  }
};

/**
 * Get domain name from url
 */
export const getDomainFromUrl = (url: string): string => {
  const match = url && url.match(/^https?:\/\/[^\/?]+/i);
  return match && match[0];
};
