import { ErrorContext } from '@app/core/models';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/environment.service';
import { getSearchParamFromUrl } from '@app/shared/utils';
import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { buildMailtoHref, getContactSupportEmails } from '@app/core/config/contact-support';
import { LocaleService } from '@app/core/services';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { ContactUsButtonComponent } from '@app/shared/components/contact-us/contact-us-button/contact-us-button.component';
import { TrackingDirective } from '@app/core/services/tracking/tracking.directive';

@Component({
  selector: 'app-set-password-failure-page',
  templateUrl: './set-password-failure-page.component.html',
  styleUrls: ['./set-password-failure-page.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule, ContactUsButtonComponent, TrackingDirective],
})
export class SetPasswordFailurePageComponent implements OnInit {
  callback: string;

  mailto: string;

  ciamSupportEmails: string[];

  hideSupportBt = false;

  constructor(
    private router: Router,
    private contactSupportService: ContactSupportService,
    public env: EnvironmentService,
    private localeService: LocaleService,
  ) {
    this.setSupportEmail();
  }
  ngOnInit(): void {
    this.hideSupportBt = environment.hideContactSupport.includes(this.localeService.getLanguageCode()) ?? false;
    if (history.state && history.state.data) {
      this.callback = history.state.data;

      const { subject, body } = this.contactSupportService.buildEmail({
        context: ErrorContext.FORGOT,
        redirectUri: this.callback,
        userLocale: history.state.data?.user?.locale,
        clientId: getSearchParamFromUrl('client'),
        apiCodeError: '',
        apiUrlError: '',
        apiErrorReturn: '',
      });
      this.mailto = buildMailtoHref(this.ciamSupportEmails, subject, body);
    } else {
      window.location.href = this.env.getGlobalRedirectURI();
      this.redirect();
    }
  }
  private setSupportEmail() {
    this.ciamSupportEmails = getContactSupportEmails();
  }

  /**
   * Retry reset password
   */
  retryReset() {
    this.router.navigate(['/forgot'], { queryParams: { redirect_uri: this.callback } });
  }

  /**
   * Redirect
   */
  private redirect() {
    window.location.href = this.callback;
  }
}
