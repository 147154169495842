import * as objectPath from 'object-path';

import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AttributeType, FormField } from '../../../../core/models';
import { emailValidator } from '../../../../shared/validators';

@Injectable()
export class FieldsFormService {
  constructor(private fb: UntypedFormBuilder) {}

  /**
   * Transform values into a FormGroup
   */
  valuesToFormGroup(values: any): UntypedFormGroup {
    const formGroupContent = {};
    if (values) {
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (typeof values === 'object') {
          formGroupContent[key] = this.valuesToFormGroup(val);
        } else {
          formGroupContent[key] = [val];
        }
      });
    }
    return this.fb.group(formGroupContent);
  }

  /**
   * Transform a collection of fields into a FormGroup
   */
  fieldsToForm(fields: FormField[], initialObject?): UntypedFormGroup {
    const formGroupContent = {};
    fields.forEach((field) => {
      if (!field.hiddenEdit) {
        const formControlContent = [];
        if (initialObject) {
          let initialVal = objectPath.get(initialObject, field.path);
          if (field.getter) {
            initialVal = field.getter(initialVal);
          }
          formControlContent.push(initialVal);
        } else {
          formControlContent.push('');
        }
        let validators = [];
        if (field.required) {
          validators.push(Validators.required);
        }
        if (field.type === AttributeType.Email) {
          validators.push(emailValidator());
        }
        if (field.validators) {
          validators = validators.concat(field.validators);
        }
        formControlContent.push(validators);
        formGroupContent[field.id] = formControlContent;
      }
    });
    return this.fb.group(formGroupContent);
  }

  /**
   * Transform angular form to model (Object)
   * 1 - Field contains setter and a path then update the objectPath
   * 2 - Field contains setter and not a path then update the res object
   * 3 - Update other fields
   */
  formToModel(fields: FormField[], formGroup: UntypedFormGroup) {
    let res = {};
    Object.keys(formGroup.value).forEach((id) => {
      const field = fields.filter((f) => !f.hiddenEdit && f.id === id)[0];

      if (formGroup.controls[id].dirty && field && !field.disabled) {
        const val = formGroup.value[id];

        if (field.setter && field.path) {
          /*1*/
          objectPath.set(res, field.path, field.setter(objectPath.get(res, field.path), val));
        } else if (field.setter && !field.path) {
          /*2*/
          res = field.setter(objectPath.get(res, field.path), val);
        } else {
          /*3*/
          objectPath.set(res, field.path, val);
        }
      }
    });

    return res;
  }
}
