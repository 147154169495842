import { ApiError } from './api-error';

export interface RootErrorPageState {
  error: ApiError;
  infos: RootErrorPageInfos;
}

export interface RootErrorPageInfos {
  context: ErrorContext;
  title: string;
  summary: string;
  details: string;
}

export enum ErrorContext {
  REGISTER = 'register',
  FORGOT = 'forgot',
}

export const STORAGE_KEY_ERROR = 'error_details';

export interface ErrorTextsDisplayed {
  contactSupport: boolean;
  title: boolean;
  summary: boolean;
  details: boolean;
  goBack: boolean;
  additionnalText?: string;
}
