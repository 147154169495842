export class DetectMobile {
  /**
   * Helper to detect if the user are on mobile or desktop
   */
  static isMobile(): boolean {
    return document.body.clientWidth < 960;
  }

  static isSafari(): boolean {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Apple Safari')) {
      return true;
    }
    return false;
  }
}
