import { Component, Input } from '@angular/core';
import { MarsPrivacyPolicyService } from '@app/core/services/mars-privacy-policy.service';
import { RCAccordionMode } from '@rc/ui';
import { SpecificConsentsCheckboxComponentEnum, SpecificCountryConsents } from 'src/app/core/models';
import { KeyTranslateLegalConsent } from './consent.config';

@Component({
  selector: 'app-detail-terms',
  templateUrl: './detail-terms.component.html',
  styles: [
    `
      /* rc-link and link-translate should not be deleted, because they are potentially used in link phrase translations  */
      .rc-link {
        color: #444;
        cursor: pointer;
        font-family: 'DINProMedium', Roboto, Avenir, Helvetica, Arial, sans-serif;
        border-bottom: 1px solid #d7d7d7;
      }
      .label__checkbox {
        margin-right: 5px;
      }
    `,
  ],
})
export class DetailTermsComponent {
  @Input() checkboxConsent: SpecificCountryConsents;
  legalConsentText = KeyTranslateLegalConsent['DEFAULT'];
  @Input() set selectedCountry(value: string) {
    this.legalConsentText = KeyTranslateLegalConsent[value] ?? KeyTranslateLegalConsent['DEFAULT'];
  }
  RCAccordionMode = RCAccordionMode;

  constructor(private marsPrivacyPolicyService: MarsPrivacyPolicyService) {}

  get SpecificConsentsCheckboxComponentEnum(): typeof SpecificConsentsCheckboxComponentEnum {
    return SpecificConsentsCheckboxComponentEnum;
  }

  /**
   * Open terms depending on country selection
   */
  openTermsHref(): string {
    return this.marsPrivacyPolicyService.openTermsHref();
  }
}
