import { isPlatformBrowser } from '@angular/common';
import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken');

export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: (platformId) => {
    if (!isPlatformBrowser(platformId)) {
      throw new Error('"window" object is only available on browser plateform.');
    }
    return window;
  },
  deps: [PLATFORM_ID],
};
