import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/environment.service';
import { SetUserPasswordRequestBody } from '@app/core/models/register-service';
import { RegisterService } from '@app/core/services/register.service';
import { TrackingService } from '@app/core/services/tracking/tracking.service';
import { CustomValidators } from '@app/shared/validators';
import { LocaleService } from '@app/core/services';
import { TranslateModule } from '@ngx-translate/core';
import { RCFieldModule, RCButtonModule } from '@rc/ui';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-set-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['./set-password-page.component.scss'],
  standalone: true,
  imports: [NgIf, LoaderComponent, FormsModule, ReactiveFormsModule, RCFieldModule, TranslateModule, RCButtonModule],
})
export class SetPasswordPageComponent implements OnInit, OnDestroy {
  public showPassword = false;
  public showPasswordConfirm = false;
  public showPopover = false;
  public setPasswordForm: UntypedFormGroup;
  public submitted = false;
  // Validation message in case of error in form
  public validationMessages;
  public passwordError: string;

  private translateRulesKey = [
    'fieldRules_required',
    'setPassword_rules1',
    'setPassword_rules2',
    'setPassword_rules3',
    'setPassword_rules4',
    'setPassword_rules5',
    'fieldRules_same',
  ];
  private _destroyed$ = new Subject<void>();
  private _callback: string;
  private _token: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private router: Router,
    private localeService: LocaleService,
    private trackingService: TrackingService,
    private environmentService: EnvironmentService,
  ) {
    // Get form rules translation
    this.localeService.translateGet(this.translateRulesKey).subscribe((translations) => {
      if (translations) {
        this.validationMessages = {
          password: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'hasNumber', message: translations.setPassword_rules4 },
            { type: 'hasCapitalCase', message: translations.setPassword_rules3 },
            { type: 'hasSmallCase', message: translations.setPassword_rules2 },
            { type: 'hasSpecialCharacters', message: translations.setPassword_rules5 },
            { type: 'minlength', message: translations.setPassword_rules1 },
          ],
          passwordConfirm: [
            { type: 'required', message: translations.fieldRules_required },
            { type: 'NoPassswordMatch', message: translations.fieldRules_same },
          ],
        };
      }
    });
  }

  ngOnInit() {
    this.localeService.setTitle('setPassword_title');
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        try {
          const decoded = JSON.parse(atob(fragment));
          this._callback = decoded.callback;
          this._token = decoded.token;
        } catch (e) {}
        if (!this._callback || !this._token) {
          this.router.navigate(['/error']);
        }
      } else {
        this.notAuthorized();
      }
    });

    // Build form
    this.buildSetPasswordForm();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  popoverOpen() {
    this.showPopover = true;
  }
  popoverClose() {
    this.showPopover = false;
  }

  onSubmit(formData) {
    if (this.submitted) {
      return;
    }
    this.submitted = true;

    // Send data
    this.sendData(formData.password);
  }

  get passwordControl() {
    return this.setPasswordForm.get('password');
  }

  get passwordConfirmControl() {
    return this.setPasswordForm.get('passwordConfirm');
  }

  private notAuthorized() {
    window.location.href = this.environmentService.getGlobalRedirectURI();
  }

  private buildSetPasswordForm() {
    this.setPasswordForm = this.formBuilder.group(
      {
        password: new UntypedFormControl(null, [
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }, true),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }, true),
            // check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }, true),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }, true),
            // Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ]),
        passwordConfirm: new UntypedFormControl(null, [Validators.compose([Validators.required])]),
      },
      {
        // Check whether our password and confirm password match
        validators: CustomValidators.passwordMatchValidator,
      },
    );
    this.setPasswordForm.controls.password.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => this.setPasswordForm.controls.passwordConfirm.updateValueAndValidity()),
      )
      .subscribe();
  }

  private sendData(password: string) {
    const body: SetUserPasswordRequestBody = { token: this._token, password };
    this.registerService
      .setUserPassword(body)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (response) => {
          try {
            const { sessionToken } = JSON.parse(response);

            const url = new URL(this._callback);
            const search = url.search ? url.search.substring(1).split('&') : [];
            search.push(`token=${sessionToken}`);
            url.search = `?${search.join('&')}`;

            this._callback = url.href;
          } catch (e) {}
          this.trackingService.pushEvent('resetPassword.success');
          this.router.navigate(['/reset/success'], { state: { data: this._callback } });
        },
        (error) => {
          this.trackingService.pushEvent('resetPassword.error');
          if (error.status === 403) {
            this.passwordError = body.password;
            this.submitted = false;
          } else {
            this.router.navigate(['/reset/failure'], { state: { data: this._callback } });
          }
        },
      );
  }
}
