import { NgModule } from '@angular/core';
import { ActivateComponent } from './components/activate/activate.component';
import { SharedModule } from '../../shared';
import { ActivatePageRoutingModule } from './activate-page-routing.module';

@NgModule({
  imports: [SharedModule, ActivatePageRoutingModule],
  declarations: [ActivateComponent],
})
export class ActivatePageModule {}
