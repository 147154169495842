import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/core/environment.service';
import { addSearchToUrl } from '../../../../shared/utils';

@Component({
  selector: 'app-set-password-success-page',
  templateUrl: './set-password-success-page.component.html',
  styleUrls: ['./set-password-success-page.component.scss'],
})
export class SetPasswordSuccessPageComponent implements OnInit {
  callback: string;

  constructor(public env: EnvironmentService) {}

  ngOnInit(): void {
    if (history.state && history.state.data) {
      this.callback = history.state.data;
      this.countdownAndRedirect();
    } else {
      window.location.href = this.env.getGlobalRedirectURI();
      this.countdownAndRedirect();
    }
  }

  /**
   * Countdown and redirect
   */
  private countdownAndRedirect() {
    window.setTimeout(() => {
      this.redirect();
    }, 3000);
  }

  /**
   * Redirect
   */
  private redirect() {
    window.location.href = addSearchToUrl(this.callback, 'origin', 'forgot');
  }
}
