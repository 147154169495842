<div class="root-error">
  <img
    class="image"
    [ngClass]="{
      'image--placeholder': env.isWhiteLabel
    }"
    src="./assets/images/error_page.jpg"
    alt=""
  />
  <h1 id="infosTitle" *ngIf="displayedText.title">{{ infos?.title }}</h1>

  <p id="infosSummary" *ngIf="displayedText.summary">{{ infos?.summary }}</p>

  <p id="infosDetails" *ngIf="infos?.details && displayedText.details">
    {{ infos?.details }}
  </p>

  <ng-container *ngIf="redirectUri">
    <p id="infosAddtionnalText" *ngIf="displayedText.additionnalText">{{ displayedText.additionnalText | translate }}</p>

    <!-- Prevent giving too much information such as contact support email in case users arrive on this page without context (= redirectUri) -->
    <!-- But remain on this page rather than redirecting to royalcanin.com as it is done on other pages -->
    <!-- This is better UX, for instance if an error occurred during social login and context is lost, at least users understand something went wrong -->
    <p id="infosContactSupport" *ngIf="displayedText.contactSupport">
      <app-contact-us-button id="rootErrorContactSupportLink" labelKey="contactSupportPage_title"></app-contact-us-button>
    </p>

    <p id="infosGoBack" *ngIf="displayedText.goBack">
      <a id="go-back" class="rc-link" (click)="goBack()">{{ 'registerApp_errorPageContinue' | translate }}</a>
    </p>
  </ng-container>
</div>
