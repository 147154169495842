import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocaleService } from './core/services';
import { ActivatePageModule } from './pages/activate-page/activate-page.module';
import { RootErrorPageComponent } from './pages/error-page/root-error-page/root-error-page.component';
import { RegisterPageModule } from './pages/register-page/register-page.module';
import { SetPasswordPageModule } from './pages/set-password-page/set-password-page.module';
import { SharedModule } from './shared';
import { MissingTranslationHandlerCustom } from './translate/missing-translation.handler';

// Required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ActivatePageModule,
    RegisterPageModule,
    SetPasswordPageModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHandlerCustom,
      },
      useDefaultLang: false,
    }),
  ],
  providers: [LocaleService],
  declarations: [AppComponent, RootErrorPageComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
