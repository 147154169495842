<div [ngSwitch]="checkboxConsent.id_component">
  <ng-container *ngSwitchCase="SpecificConsentsCheckboxComponentEnum.LEGAL_CONSENT">
    <span [innerHTML]="legalConsentText | translate: { marsPrivacyURL: openTermsHref() }"></span>
  </ng-container>

  <ng-container *ngSwitchCase="SpecificConsentsCheckboxComponentEnum.KR_PRIVACY_COLLECT">
    <rc-accordion
      [mode]="RCAccordionMode.SUFFIX"
      [items]="[{ label: 'tickbox_korea-tickbox1-title-1' | translate }]"
      [labelButtonClose]="'action-hide_content' | translate"
    >
      <ng-template>
        <app-detail-terms-kr-privacy-collect></app-detail-terms-kr-privacy-collect>
      </ng-template>
    </rc-accordion>
  </ng-container>
  <ng-container *ngSwitchCase="SpecificConsentsCheckboxComponentEnum.KR_PRIVACY_TRANSFER">
    <rc-accordion
      [mode]="RCAccordionMode.SUFFIX"
      [items]="[{ label: 'tickbox_korea-tickbox2-title-1' | translate }]"
      [labelButtonClose]="'action-hide_content' | translate"
      [labelButtonOpen]="'action-see_content' | translate"
    >
      <ng-template>
        <app-detail-terms-kr-privacy-transfer></app-detail-terms-kr-privacy-transfer>
      </ng-template>
    </rc-accordion>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span [innerHTML]="checkboxConsent.text | translate"></span>
  </ng-container>
</div>
