import { ErrorContext } from '@app/core/models';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/environment.service';
import { getSearchParamFromUrl } from '@app/shared/utils';
import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { buildMailtoHref, getContactSupportEmails } from '@app/core/config/contact-support';

@Component({
  selector: 'app-set-password-failure-page',
  templateUrl: './set-password-failure-page.component.html',
  styleUrls: ['./set-password-failure-page.component.scss'],
})
export class SetPasswordFailurePageComponent implements OnInit {
  callback: string;

  mailto: string;

  ciamSupportEmails: string[];

  constructor(private router: Router, private contactSupportService: ContactSupportService, public env: EnvironmentService) {
    this.setSupportEmail();
  }
  ngOnInit(): void {
    if (history.state && history.state.data) {
      this.callback = history.state.data;

      const { subject, body } = this.contactSupportService.buildEmail({
        context: ErrorContext.FORGOT,
        redirectUri: this.callback,
        userLocale: history.state.data?.user?.locale,
        clientId: getSearchParamFromUrl('client'),
        apiCodeError: '',
        apiUrlError: '',
        apiErrorReturn: '',
      });
      this.mailto = buildMailtoHref(this.ciamSupportEmails, subject, body);
    } else {
      window.location.href = this.env.getGlobalRedirectURI();
      this.redirect();
    }
  }
  private setSupportEmail() {
    this.ciamSupportEmails = getContactSupportEmails();
  }

  /**
   * Retry reset password
   */
  retryReset() {
    this.router.navigate(['/forgot'], { queryParams: { redirect_uri: this.callback } });
  }

  /**
   * Redirect
   */
  private redirect() {
    window.location.href = this.callback;
  }
}
