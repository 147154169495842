export interface RawApiError {
  code: string;
  name: string;
  description: string;
}

export interface ApiError {
  code: string;
  name: string;
  description?: ErrorDescription;
}

export type ErrorDescription = UnknownErrorDescription | OktaErrorDescription | SendGridErrorDescription;

export interface UnknownErrorDescription extends ErrorDescriptionBase {
  ciamType: 'Unknown';
  data: any;
}

export interface OktaErrorDescription extends ErrorDescriptionBase {
  ciamType: 'Okta';
  errorCauses: string[];
  errorCode: string;
  errorId: string;
  errorLink: string;
  errorSummary: string;
}

export interface SendGridErrorDescription extends ErrorDescriptionBase {
  ciamType: 'SendGrid';
}

export interface ErrorDescriptionBase {
  ciamType: ErrorDescriptionType;
}

export type ErrorDescriptionType = 'Unknown' | 'Okta' | 'SendGrid';
