const MARS_FOOTER_DEFAULT_URL = 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com';
/**
 * Phrase access keys
 */
export const phraseAccessToken = '4df0964d40eec6325d9ece9e441948e50221a31eba944cb50a3e320f5df1affa';
export const phraseProjectId = '11d1e45d9e7635265998248ed454f90d';

/**
 * Languages enum
 */
export enum Languages {
  bgBG = 'bg-BG',
  csCZ = 'cs-CZ',
  daDK = 'da-DK',
  deDE = 'de-DE',
  deAT = 'de-AT',
  deCH = 'de-CH',
  deLI = 'de-LI',
  elGR = 'el-GR',
  enGB = 'en-GB',
  enBE = 'en-BE',
  enUS = 'en-US',
  enCA = 'en-CA',
  esES = 'es-ES',
  esAR = 'es-AR',
  esCO = 'es-CO',
  esMX = 'es-MX',
  etEE = 'et-EE',
  fiFI = 'fi-FI',
  frFR = 'fr-FR',
  frBE = 'fr-BE',
  frCH = 'fr-CH',
  frCA = 'fr-CA',
  heIL = 'he-IL',
  hrHR = 'hr-HR',
  huHU = 'hu-HU',
  idID = 'id-ID',
  itIT = 'it-IT',
  itCH = 'it-CH',
  jaJP = 'ja-JP',
  koKR = 'ko-KR',
  ltLT = 'lt-LT',
  lvLV = 'lv-LV',
  nbNO = 'nb-NO',
  nlNL = 'nl-NL',
  nlBE = 'nl-BE',
  plPL = 'pl-PL',
  ptPT = 'pt-PT',
  ptBR = 'pt-BR',
  roRO = 'ro-RO',
  ruRU = 'ru-RU',
  slSL = 'sl-SL',
  skSK = 'sk-SK',
  svSE = 'sv-SE',
  thTH = 'th-TH',
  trTR = 'tr-TR',
  ukUA = 'uk-UA',
  zhCN = 'zh-CN',
  zhHK = 'zh-HK',
  zhTW = 'zh-TW',
}
/**
 * Languages as array ['bg', 'cs-CZ', ...]
 */
export const supportedLanguages: string[] = Object.values(Languages);

/**
 * Language data
 * For languages available in Phrase
 */
interface AvailableLanguages {
  name: Languages;
  phraseId: string;
  footerUrl: string;
}
export const availableLanguages: AvailableLanguages[] = [
  {
    name: Languages.bgBG,
    phraseId: 'af2952e222e8192121503be062e6366a',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.csCZ,
    phraseId: '1a892924b282835fceb42801f76b75c8',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.daDK,
    phraseId: 'c7fe6c6fccb7a53807cf0ca0730a882d',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.deDE,
    phraseId: 'f49cde41172ef931a1eadae757434b95',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-de-DE',
  },
  {
    name: Languages.deAT,
    phraseId: 'a8fbd820ee69318f111b74255609deab',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-de-AT',
  },
  {
    name: Languages.deCH,
    phraseId: '1e26278956af8f9fc560b48617ecf185',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-de-CH',
  },
  {
    name: Languages.deLI,
    phraseId: '59380c335fcec3f3bf1a7f2e97b6e02f',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-de-LI',
  },
  {
    name: Languages.elGR,
    phraseId: '6d60b8ffcbc5979f697d6804ac449d54',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.enGB,
    phraseId: '8121d1663bd08121b43ac2752f8ca008',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.enBE,
    phraseId: 'a512b2126b4e4bce45a13f52404cf2aa',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.enUS,
    phraseId: '1d242606edc2aa5d36f782ea7be3e16a',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-en-us',
  },
  {
    name: Languages.enCA,
    phraseId: '52d5c7b37adc9daa102f25315c3456b5',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.esES,
    phraseId: '812f33fafd4c17e67d34852b368b1b76',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-es-es',
  },
  {
    name: Languages.esAR,
    phraseId: '75c7a3cf397c33a476fdf89394cc3c21',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.esCO,
    phraseId: '1e7abb3047597556197b41fe98ec91c6',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.esMX,
    phraseId: '233fd7ed9c5cc7aa5aa00a2b6fadaa43',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.etEE,
    phraseId: '52858c923c4fc680bd12e8144452dae2',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.fiFI,
    phraseId: 'c7486fa5e74f00a4ba56f2f80692f6d0',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.frFR,
    phraseId: '3160e90124dcbb8d00f07ebc4a114e5f',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-fr',
  },
  {
    name: Languages.frBE,
    phraseId: 'c5fe323ebd5b4418a3f843b9645a23c9',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.frCH,
    phraseId: '952a8dde1c9f18a308c8e08e5c54dd63',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-fr-CH',
  },
  {
    name: Languages.frCA,
    phraseId: '6246d2f3bf49a84f7203308e6ab5ab34',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.heIL,
    phraseId: 'e1603dc1491ee8d9b63861c405c38cd0',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.hrHR,
    phraseId: '99888dfd9f8497406fa753a15beb8a84',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.huHU,
    phraseId: '7a6ce95936a95e8fcad02691d6d6ceb1',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.idID,
    phraseId: '1a50e5323bdf68af2bee797407123ab0',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.itIT,
    phraseId: '8c1b6cfd74cbf0a3ffaa143ca6935561',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.itCH,
    phraseId: 'a84d7f798b9f0f1c5b88d61aea03d91f',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-it-CH',
  },
  {
    name: Languages.jaJP,
    phraseId: '0916e4497e7ef7a308bd076f6760896a',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-jp-jp',
  },
  {
    name: Languages.koKR,
    phraseId: '4ca335a99820870abc8b68c6c3539ee5',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-ko-kr',
  },
  {
    name: Languages.ltLT,
    phraseId: '2b3decb53e265fd91b3b2e2736d94074',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.lvLV,
    phraseId: 'dff8d1045e32036a61dcfe5bcb2415ac',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.nbNO,
    phraseId: '38ce1007955f71aed5fe22d72e1baa0b',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.nlNL,
    phraseId: 'f2014afa59203d0423979762df5ddfac',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.nlBE,
    phraseId: '01b64268567c9a0eabf6e21b2c87fedb',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.plPL,
    phraseId: '5de85ed0573a95457cf27721ee8cab66',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.ptPT,
    phraseId: 'f33c5cf799577c2829d4a9321a327774',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-pt-pt',
  },
  {
    name: Languages.ptBR,
    phraseId: 'fc924c41588242c1f1b9421b804d8cc9',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.roRO,
    phraseId: 'a4fb5a47b7b9e19181ca2fd6a0dd22e1',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.ruRU,
    phraseId: '27be498feac94becfac75f93c3dcb016',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-ru-ru',
  },
  {
    name: Languages.slSL,
    phraseId: '298aef0782ecd0ccf0569a17e03d6b87',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.skSK,
    phraseId: '74ed77a96e2d86ffa3b77f645be7685b',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.svSE,
    phraseId: '0dc7e80192b403f43e281e4144b6baf8',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.thTH,
    phraseId: 'd909a35db7103d0385b515a1b506f038',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.trTR,
    phraseId: 'b257577750c8066454a5120f0bfa6371',
    footerUrl: 'https://footer.mars.com/js/footer.js.aspx?welcome-royalcanin-com-tr-TR',
  },
  {
    name: Languages.ukUA,
    phraseId: 'e60b8644ecfc55d16a8a68d2b43d2ae7',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.zhCN,
    phraseId: 'a61163980b74c44c845a8077152b87b3',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.zhHK,
    phraseId: 'f70753d91c7e956fa76da2c8701a999e',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
  {
    name: Languages.zhTW,
    phraseId: 'abe90539950228a7c99d4cbf298045d7',
    footerUrl: MARS_FOOTER_DEFAULT_URL,
  },
];

export const FALLBACK_LANGUAGE = Languages.enUS;

export function getCurrentPhraseLanguage(lang: string): string {
  return (
    supportedLanguages.find((l) => l === lang) ||
    supportedLanguages.find((l) => l.split('-')[0] === lang.split('-')[0]) ||
    FALLBACK_LANGUAGE
  );
}
