import { Injectable } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { Message } from '@app/shared/components/message/message';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private message = new Subject<Message>();
  private clear = new Subject<string | null>();
  addMessage$ = this.message.asObservable();
  clearMessage$ = this.clear.asObservable();

  add(message: Message) {
    this.message.next(message);
    if (message.autoCloseDelay) {
      interval(message.autoCloseDelay)
        .pipe(take(1))
        .subscribe(() => this.clearById(message.id));
    }
  }

  clearById(id: string) {
    this.clear.next(id);
  }

  clearAll() {
    this.clear.next(null);
  }
}
