import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../../shared/utils';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(str: string): string {
    if (str === '') {
      return '--';
    }

    const num = Constants.LIMIT_STRING;

    if (str.length <= num) {
      return str;
    }

    return str.slice(0, num) + '...';
  }
}
