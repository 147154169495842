export enum InputType {
  Text = 'text',
  Checkbox = 'checkbox',
  Segmented = 'segmented',
  RadioImage = 'radioimage',
  Radio = 'radio',
  Select = 'select',
  Autocomplete = 'autocomplete',
  Number = 'number',
  Date = 'date',
  Textarea = 'textarea',
}
