import { Country } from './countries.types';
import { LocaleData } from 'i18n-iso-countries';

export const mapToCountries = (localeData: LocaleData): Country[] => {
  return Object.keys(localeData.countries).map((code) => {
    const countryNames = localeData.countries[code];
    return {
      code,
      label: Array.isArray(countryNames) ? countryNames[0] : countryNames,
    };
  });
};
