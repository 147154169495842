import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { EnvironmentService } from '@app/core/environment.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectUriGuard implements CanActivate {
  constructor(public env: EnvironmentService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const params = route.queryParams;
    const redirect_uri = params.redirect_uri;
    if (!redirect_uri) {
      window.location.href = this.env.getGlobalRedirectURI();
      return false;
    }
    return true;
  }
}
