import { environment } from '../../../environments/environment';

export function getIssuerUrl(path = ''): string {
  const url = [`https://${environment.okta.domain}/oauth2/default`];
  if (path) {
    if (/^\//.test(path)) {
      throw new Error(`getIssuerUrl: "path" should NOT start with a slash (${path})`);
    }
    url.push(path);
  }
  return url.join('/');
}

export enum ISSUER_PATH {
  userinfo = 'v1/userinfo',
}
