import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
  /*
   * Capitalize the first letter of the string
   * Takes a string as a value.
   * Usage:
   *  value | capitalizefirst
   * Example:
   *  // value.name = daniel blabla
   *  {{ value.name | capitalizefirst  }}
   *  fromats to: Daniel blabla
   */
  transform(value: string): string {
    if (value) {
      const str = value.toLowerCase();
      return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
    }
    return 'Not assigned';
  }
}
