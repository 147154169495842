import { AttributeType } from './attribute-type';
import { ValidationErrors } from '@angular/forms';
import { InputType } from '../models/input-type';
import { Observable } from 'rxjs';

export interface FormField {
  id: string;
  path?: string;
  getter?: any;
  setter?: any;
  label?: string;
  type?: AttributeType;
  inputType?: InputType;
  disabled?: boolean;
  default?: any;
  required?: boolean;
  placeholder?: string;
  hiddenEdit?: boolean;
  hiddenRead?: boolean;
  validators?: ValidationErrors[];
  disableAutocomplete?: boolean;
  items?: Observable<any[]>;
  keyPath?: string;
  labelPath?: string;
}

export interface FormFields {
  [key: string]: FormField;
}
