<!-- Register form -->
<ng-container *ngIf="submitted">
  <div class="rc-loader__full-page">
    <app-loader></app-loader>
  </div>
</ng-container>

<app-auth-login [clientId]="clientId"></app-auth-login>

<form
  [formGroup]="registerForm"
  (submit)="onSubmit(registerForm.value)"
  id="register-form"
  class="rc-content-v-middle form-container"
  autocomplete="'disabled'"
>
  <div class="form-container__grid">
    <!-- First Name -->
    <div class="register-form__input">
      <rc-field
        id="registerFieldFirstName"
        [autocomplete]="'disabled'"
        maxlength="50"
        formControlName="firstName"
        [placeholder]="'registerApp_fieldFirstName' | translate"
        [error]="
          firstNameControl.touched &&
          firstNameControl.errors &&
          ((firstNameControl.hasError(validationMessages.firstName[0].type) && validationMessages.firstName[0].message) ||
            (firstNameControl.hasError('blankString') && validationMessages.firstName[1].message) ||
            (firstNameControl.hasError(validationMessages.firstName[1].type) && validationMessages.firstName[1].message) ||
            (firstNameControl.hasError(validationMessages.firstName[2].type) && validationMessages.firstName[2].message) ||
            (firstNameControl.hasError(validationMessages.firstName[3].type) && validationMessages.firstName[3].message))
        "
      ></rc-field>
    </div>

    <div class="register-form__input" [ngClass]="{ 'register-form__input--combined': isCountryToShowMiddleNameField }">
      <!-- Middle Name -->
      <rc-field
        *ngIf="isCountryToShowMiddleNameField"
        id="registerFieldMiddleName"
        [autocomplete]="'disabled'"
        class="registerFieldMiddleName"
        formControlName="middleName"
        [placeholder]="'registerApp_fieldMiddleName' | translate"
        maxlength="50"
        [error]="
          middleNameControl.touched &&
          middleNameControl.errors &&
          ((middleNameControl.hasError(validationMessages.middleName[0].type) && validationMessages.middleName[0].message) ||
            (middleNameControl.hasError('blankString') && validationMessages.middleName[0].message))
        "
      ></rc-field>

      <!-- Last Name  -->
      <rc-field
        id="registerFieldLastName"
        [autocomplete]="'disabled'"
        formControlName="lastName"
        [placeholder]="'registerApp_fieldLastName' | translate"
        maxlength="50"
        [error]="
          lastNameControl.touched &&
          lastNameControl.errors &&
          ((lastNameControl.hasError(validationMessages.lastName[0].type) && validationMessages.lastName[0].message) ||
            (lastNameControl.hasError('blankString') && validationMessages.lastName[1].message) ||
            (lastNameControl.hasError(validationMessages.lastName[1].type) && validationMessages.lastName[1].message))
        "
      ></rc-field>
    </div>

    <!-- Email -->
    <div class="register-form__input" #registerLoginField *ngIf="loginType === LoginType.email">
      <rc-field
        id="registerFieldEmail"
        [autocomplete]="'disabled'"
        formControlName="email"
        [placeholder]="'registerApp_fieldEmailAddress' | translate"
        [error]="
          emailControl.touched &&
          emailControl.errors &&
          ((emailControl.hasError(validationMessages.email[0].type) && validationMessages.email[0].message) ||
            (emailControl.hasError(validationMessages.email[1].type) && validationMessages.email[1].message))
        "
      >
      </rc-field>
    </div>

    <!-- Country -->
    <div class="register-form__input">
      <rc-autocomplete
        id="registerFieldCountry"
        formControlName="countryLabel"
        [placeholder]="'registerApp_fieldCountry' | translate"
        [items]="countryLabels"
        [error]="
          countryLabelControl.touched &&
          countryLabelControl.errors &&
          ((countryLabelControl.hasError(validationMessages.countryLabel[0].type) && validationMessages.countryLabel[0].message) ||
            (countryLabelControl.hasError(validationMessages.countryLabel[1].type) && validationMessages.countryLabel[1].message))
        "
      >
      </rc-autocomplete>
    </div>
    <div class="register-form__input" #registerLoginField *ngIf="loginType === LoginType.primaryPhone">
      <rc-phone-field
        id="registerFieldPhone"
        [label]="'registerApp_fieldPrimaryPhone' | translate"
        formControlName="primaryPhone"
        [error]="primaryPhoneControl.touched && getFirstErrorControl(primaryPhoneControl) | translate"
      ></rc-phone-field>
    </div>
    <!-- Password -->
    <div class="register-form__input register-form__input--passoword" [class.mobile-auto-height]="showPopover">
      <rc-field
        id="registerFieldPassword"
        [autocomplete]="'disabled'"
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        [placeholder]="'registerApp_fieldEnterPassword' | translate"
        (focus)="popoverOpen()"
        (blur)="popoverClose()"
        [error]="passwordControl.touched && passwordControl.invalid && !showPopover && ('SetPassword_rules6' | translate)"
      >
        <div suffix class="password-eye" (click)="showPassword = !showPassword">
          <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="240" y="96">
            <g data-name="View/Show" fill="#767676">
              <path
                d="M16 21c-4.16 0-7.63-4.19-7.77-4.37a1 1 0 0 1 0-1.26C8.37 15.19 11.84 11 16 11s7.63 4.19 7.77 4.37a1 1 0 0 1 0 1.26C23.63 16.81 20.16 21 16 21zm-5.65-5c1 1 3.28 3 5.65 3s4.47-1.8 5.65-3c-1-1-3.28-3-5.65-3-2.22 0-4.47 1.8-5.65 3z"
              />
              <circle cx="16" cy="16" r="2" />
            </g>
          </svg>

          <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="144" y="128">
            <g data-name="View/Hide" fill="#767676">
              <path
                d="M23.77 15.37a15.35 15.35 0 0 0-3.45-3l1.45-1.74a1 1 0 0 0-1.54-1.28l-1.76 2.11A7.32 7.32 0 0 0 16 11c-4.16 0-7.63 4.19-7.77 4.37a1 1 0 0 0 0 1.26 15.35 15.35 0 0 0 3.45 3l-1.45 1.74a1 1 0 0 0 .13 1.41A1 1 0 0 0 11 23a1 1 0 0 0 .77-.36l1.76-2.11A7.32 7.32 0 0 0 16 21c4.16 0 7.63-4.19 7.77-4.37a1 1 0 0 0 0-1.26zM10.35 16c1.18-1.2 3.43-3 5.65-3a5 5 0 0 1 1.08.14L13 18.06A13.37 13.37 0 0 1 10.35 16zM16 19a5 5 0 0 1-1.08-.14L19 13.94A13.37 13.37 0 0 1 21.65 16c-1.18 1.2-3.43 3-5.65 3z"
              />
              <circle cx="16" cy="16" r="2" />
            </g>
          </svg>
        </div>
      </rc-field>

      <div *ngIf="showPopover" class="rc-input--error-message form-container__password-errors popover">
        <div class="form-container__password-rule" translate>SetPassword_rules0</div>
        <div
          class="form-container__password-rule"
          [class.success__color]="
            !(
              passwordControl.hasError(validationMessages.password[1].type) || passwordControl.hasError(validationMessages.password[0].type)
            )
          "
        >
          {{ validationMessages.password[1].message }}
        </div>
        <div
          class="form-container__password-rule"
          [class.success__color]="
            !(
              passwordControl.hasError(validationMessages.password[2].type) || passwordControl.hasError(validationMessages.password[0].type)
            )
          "
        >
          {{ validationMessages.password[2].message }}
        </div>
        <div
          class="form-container__password-rule"
          [class.success__color]="
            !(
              passwordControl.hasError(validationMessages.password[3].type) || passwordControl.hasError(validationMessages.password[0].type)
            )
          "
        >
          {{ validationMessages.password[3].message }}
        </div>
        <div
          class="form-container__password-rule"
          [class.success__color]="
            !(
              passwordControl.hasError(validationMessages.password[4].type) || passwordControl.hasError(validationMessages.password[0].type)
            )
          "
        >
          {{ validationMessages.password[4].message }}
        </div>
        <div
          class="form-container__password-rule"
          [class.success__color]="
            !(
              passwordControl.hasError(validationMessages.password[5].type) || passwordControl.hasError(validationMessages.password[0].type)
            )
          "
        >
          {{ validationMessages.password[5].message }}
        </div>
        <div class="popover__arrow--scale">
          <div class="popover__arrow"></div>
        </div>
      </div>
    </div>

    <!-- Password Confirm -->
    <div class="register-form__input register-form__input--password-confirm">
      <rc-field
        id="registerFieldConfPassword"
        [autocomplete]="'disabled'"
        formControlName="passwordConfirm"
        [type]="showPasswordConfirm ? 'text' : 'password'"
        [placeholder]="'registerApp_fieldConfirmPassword' | translate"
        [error]="
          passwordConfirmControl.touched &&
          registerForm.hasError(validationMessages.passwordConfirm[1].type) &&
          validationMessages.passwordConfirm[1].message
        "
      >
        <div suffix class="password-eye" (click)="showPasswordConfirm = !showPasswordConfirm">
          <svg *ngIf="!showPasswordConfirm" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="240" y="96">
            <g data-name="View/Show" fill="#767676">
              <path
                d="M16 21c-4.16 0-7.63-4.19-7.77-4.37a1 1 0 0 1 0-1.26C8.37 15.19 11.84 11 16 11s7.63 4.19 7.77 4.37a1 1 0 0 1 0 1.26C23.63 16.81 20.16 21 16 21zm-5.65-5c1 1 3.28 3 5.65 3s4.47-1.8 5.65-3c-1-1-3.28-3-5.65-3-2.22 0-4.47 1.8-5.65 3z"
              />
              <circle cx="16" cy="16" r="2" />
            </g>
          </svg>

          <svg *ngIf="showPasswordConfirm" xmlns="http://www.w3.org/2000/svg" viewBox="4 0 24 32" width="24" height="32" x="144" y="128">
            <g data-name="View/Hide" fill="#767676">
              <path
                d="M23.77 15.37a15.35 15.35 0 0 0-3.45-3l1.45-1.74a1 1 0 0 0-1.54-1.28l-1.76 2.11A7.32 7.32 0 0 0 16 11c-4.16 0-7.63 4.19-7.77 4.37a1 1 0 0 0 0 1.26 15.35 15.35 0 0 0 3.45 3l-1.45 1.74a1 1 0 0 0 .13 1.41A1 1 0 0 0 11 23a1 1 0 0 0 .77-.36l1.76-2.11A7.32 7.32 0 0 0 16 21c4.16 0 7.63-4.19 7.77-4.37a1 1 0 0 0 0-1.26zM10.35 16c1.18-1.2 3.43-3 5.65-3a5 5 0 0 1 1.08.14L13 18.06A13.37 13.37 0 0 1 10.35 16zM16 19a5 5 0 0 1-1.08-.14L19 13.94A13.37 13.37 0 0 1 21.65 16c-1.18 1.2-3.43 3-5.65 3z"
              />
              <circle cx="16" cy="16" r="2" />
            </g>
          </svg>
        </div>
      </rc-field>
    </div>
  </div>

  <div class="info-container">
    <div>
      {{ this.env.getTranslationPrefix() + 'registerApp_registerInfo1' | translate }}
    </div>
    <div>
      {{ this.env.getTranslationPrefix() + 'registerApp_registerInfo2' | translate }}
      {{ 'registerApp_registerInfo3' | translate }}
    </div>
  </div>
  <!-- Consents checkboxs -->
  <div class="form-container__checkbox">
    <ng-container formArrayName="consents">
      <div
        class="form-container__checkbox__consents"
        [formGroupName]="indexConsent"
        *ngFor="let checkboxConsent of controlsAllConsents; let indexConsent = index"
      >
        <app-detail-terms
          *ngIf="!checkboxConsent.value.displayCheckbox"
          class="form-container__checkbox__consents__item"
          [checkboxConsent]="checkboxConsent.value"
          [selectedCountry]="countryLabelControl.value"
        ></app-detail-terms>

        <app-rc-checkbox
          *ngIf="checkboxConsent.value.displayCheckbox"
          [multiLine]="true"
          formControlName="isChecked"
          class="checkbox-consent"
        >
          <app-detail-terms
            class="form-container__checkbox__consents__item"
            [checkboxConsent]="checkboxConsent.value"
            [selectedCountry]="countryLabelControl.value"
          ></app-detail-terms>
        </app-rc-checkbox>
      </div>
    </ng-container>
  </div>
  <div class="form-container__button">
    <a (click)="goBack()" class="rc-link form-container__button__back">{{ 'registerApp_cancel' | translate }}</a>
    <p class="form-container__button__or">{{ 'registerApp_or' | translate }}</p>

    <rc-button [disabled]="!registerForm.valid" id="registerButton" type="submit" [name]="'registerApp_continue' | translate"> </rc-button>
  </div>
</form>
