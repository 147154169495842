import { AbstractControl, ValidatorFn } from '@angular/forms';

export function patternValidator(pattern: RegExp, customPropName: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value || pattern.test(control.value)) {
      return null;
    }
    return {
      [customPropName]: { value: control.value, pattern: pattern.toString() },
    };
  };
}

// Note: We did not use the native Angular `Validators.email` because it's not strict enough (for example, "a@b" is considered valid).
// Note: the `customPropName` default value is the same as the native Angular `Validators.email`.
export function emailValidator(customPropName = 'email'): ValidatorFn {
  // Note: the RegExp comes from: https://emailregex.com/
  return patternValidator(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[A-Za-zÀ-ÖØ-öø-ÿ0-9-]+(?:\.[A-Za-zÀ-ÖØ-öø-ÿ0-9-]+)*$/, customPropName);
}
