import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '@app/core//environment.service';
import { STORAGE_KEY_ERROR } from '@app/core/models';
import { ActivateUserRequestBody } from '@app/core/models/register-service';
import { LocaleService } from '@app/core/services';
import { RegisterService } from '@app/core/services/register.service';
import { StorageService } from '@app/core/services/storage/storage.service';
import { addSearchToUrl, getSearchParamFromUrl } from '@app/shared/utils';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { TrackingService } from '@app/core/services/tracking/tracking.service';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  standalone: true,
  imports: [NgIf, LoaderComponent, TranslateModule],
})
export class ActivateComponent implements OnInit {
  success = false;
  private callbackUri: string;

  private sessionToken: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private localeService: LocaleService,
    private trackingService: TrackingService,
    public env: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if (!fragment) {
        this.notAuthorized();
        return;
      }

      try {
        const { callback, email, token, passcode } = JSON.parse(atob(fragment));
        if (!callback || !email || !token) {
          throw new Error();
        }

        this.callbackUri = callback;
        this.sendActivationData(email, token, passcode);
      } catch (e) {
        this.router.navigate(['/error']);
      }
    });
  }

  redirect(): void {
    this.callbackUri = addSearchToUrl(this.callbackUri, 'origin', 'register');
    if (this.sessionToken) {
      this.callbackUri = addSearchToUrl(this.callbackUri, 'token', this.sessionToken);
    }
    window.location.href = this.callbackUri;
  }

  private notAuthorized() {
    window.location.href = this.env.getGlobalRedirectURI();
  }

  private sendActivationData(email: string, recoveryToken: string, passcode?: string) {
    const body: ActivateUserRequestBody = { origin: 'ciam_register_app', email, recoveryToken, passcode: passcode || undefined };

    this.registerService
      .activateUser(body)
      .pipe(
        tap((response) => {
          this.trackingService.pushEvent('registration.verifiedAccountSuccess');
          this.success = true;
          const { sessionToken } = JSON.parse(response);
          if (sessionToken) {
            this.sessionToken = sessionToken;
          }
          this.countdownAndRedirect();
        }),
        catchError((err) => {
          this.storageService.setSession(STORAGE_KEY_ERROR, {
            summary: this.translateService.instant('registerApp_errorPageActivationSummary'),
            clientId: getSearchParamFromUrl('client'),
            apiCodeError: err?.status ?? '',
            apiUrlError: err?.url ?? '',
            apiErrorReturn: err?.error?.message ?? err?.message ?? '',
            redirectUri: this.callbackUri,
            userLocale: this.localeService.getLanguage().replace('-', '_'),
          });
          this.router.navigate(['/error'], { queryParams: { client: getSearchParamFromUrl('client'), redirect_uri: this.callbackUri } });
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private countdownAndRedirect() {
    window.setTimeout(() => this.redirect(), 3000);
  }
}
