import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/core/environment.service';
import { TranslateModule } from '@ngx-translate/core';
import { SetPasswordPageComponent } from '../../components/set-password-page/set-password-page.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-set-password-page-container',
  templateUrl: './set-password-page-container.component.html',
  styleUrls: ['./set-password-page-container.component.scss'],
  standalone: true,
  imports: [NgIf, SetPasswordPageComponent, NgClass, TranslateModule],
})
export class SetPasswordPageContainerComponent {
  constructor(public env: EnvironmentService) {}
}
