import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { TrackingService } from 'src/app/core/services/tracking/tracking.service';

@Component({
  selector: 'app-cookie-settings-button',
  templateUrl: './cookie-settings-button.component.html',
  styleUrls: ['./cookie-settings-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieSettingsButtonComponent implements OnInit, OnDestroy {
  @Input() routeUrls: string[] = [];

  isHidden = true;
  subscription: Subscription;

  constructor(private trackingService: TrackingService, private router: Router, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.trackingService.oneTrustLoaded$.pipe(delay(2000)),
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
    ]).subscribe(([oneTrustLoaded, navigationEnd]) => {
      this.isHidden = !oneTrustLoaded || !this.isVisibleForThisNavigation(navigationEnd as NavigationEnd);
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private isVisibleForThisNavigation({ url }: NavigationEnd): boolean {
    return !this.routeUrls.length || this.routeUrls.includes(url);
  }
}
