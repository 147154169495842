import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converterHtmlToText',
  standalone: true,
})
export class ConverterHtmlToText implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '--';
    }

    return value.replace(/<(?:.|\n)*?>/gm, '');
  }
}
