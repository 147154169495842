import { Address } from '../../core/models/address';

export interface Qualification {
  status?: string;
  id?: number;
  code?: string;
}

export interface OptStatus {
  status?: boolean;
  source?: string;
  timestamp?: Date;
}

export interface Opt {
  email?: OptStatus;
  mobile?: OptStatus;
  print?: OptStatus;
  transferData?: OptStatus;
}

export interface JanrainUser {
  uuid?: string;
  accountType?: string;
  avatarpath?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  languageCode?: string;
  primaryAddress?: Address;
  professionalAddress?: Address;
  title?: string;
  provisional?: any;
}
