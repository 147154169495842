import { RegisterUser } from './register-user';

export type CreateUserRequestBody = RegisterUser;

export interface ActivateUserRequestBody {
  origin: 'ciam_register_app';
  email: string;
  recoveryToken: string;
  passcode?: string;
}
export interface ActivatePhoneUserRequestBody {
  locale: string;
  passcode: string;
  login: string;
}
export type ResetPasswordByPhoneRequestBody = {
  locale: string;
  passcode: string;
  callback: string;
} & (
  | { login: string; username?: never }
  | {
      login?: never;
      username: string;
    }
);

export interface SetUserPasswordRequestBody {
  token: string;
  password: string;
}

export type ResetUserPasswordRequestBody = {
  locale: string;
  callback: string;
  login: string;
};
export type SendCodeRequestBody = {
  locale: string;
  callback: string;
  login?: string;
  username?: string;
} & (
  | { primaryPhone: string; username?: never }
  | {
      primaryPhone?: never;
      username: string;
    }
);
