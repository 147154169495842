import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/core/environment.service';

@Component({
  selector: 'app-set-password-page-container',
  templateUrl: './set-password-page-container.component.html',
  styleUrls: ['./set-password-page-container.component.scss'],
})
export class SetPasswordPageContainerComponent {
  constructor(public env: EnvironmentService) {}
}
