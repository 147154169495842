export enum Programs {
  WeightMonitoring = 'weight_monitoring',
  WeightLoss = 'weight_loss',
  WeightStabilisationStep1 = 'weight_stabilization_step1',
  WeightStabilisationStep2 = 'weight_stabilization_step2',
  Growth = 'growth',
  None = 'none',
}

export enum ProductPrograms {
  WeightMaintenance = 'weight maintenance',
  WeightLoss = 'weight loss',
  Growth = 'growth',
  None = 'none',
}
