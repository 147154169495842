<!-- Main container -->
<div class="set-password-container">
  <!-- Header container -->
  <div class="set-password-container__logo">
    <img *ngIf="env.isWhiteLabel" src="./assets/logo.svg" alt="" />
    <img *ngIf="env.isRoyalCanin" src="https://dl.royalcanin.com/8-12-1/logo--primary.svg" alt="" />
  </div>

  <!-- Main content container -->
  <div class="set-password-container__main">
    <h1 class="set-password-container__main__h1">
      <span class="red__markup">{{ 'setPassword_title' | translate }}</span>
    </h1>
    <app-set-password-page></app-set-password-page>

    <div
      class="set-password-container__main__img-bloc"
      [ngClass]="{ 'set-password-container__main__img-bloc--myvetreco': env.isWhiteLabelMyVetReco }"
    >
      <img src="./assets/images/reset_password_page.jpg" alt="" />
    </div>
  </div>
</div>
