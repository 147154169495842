import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { RegisterPageEmailSuccessComponent } from './components/register-page-email-success/register-page-email-success.component';
import { RegisterPageFormComponent } from './components/register-page-form/register-page-form.component';
import { RegisterPagePrimaryPhoneSuccessComponent } from './components/register-page-primary-phone-success/register-page-primary-phone-success.component';
import { RegisterPageContainerComponent } from './container/register-page-container/register-page-container.component';
import { RegisterPageRoutingModule } from './register-page-routing.module';

@NgModule({
  imports: [
    SharedModule,
    RegisterPageRoutingModule,
    RegisterPageFormComponent,
    RegisterPageEmailSuccessComponent,
    RegisterPageContainerComponent,
    RegisterPagePrimaryPhoneSuccessComponent,
  ],
})
export class RegisterPageModule {}
