import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { termsLinks } from './terms-links';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarsPrivacyPolicyService {
  readonly DEFAULT_PATH = '/privacy-policy-us';

  private countryCode: string | null = null;
  private countryCode$$ = new BehaviorSubject<string | null>(null);
  countryCode$ = this.countryCode$$.asObservable();

  openTermsHref$ = this.countryCode$$.pipe(map(() => this.openTermsHref()));

  setCountryCode(countryCode?: string) {
    this.countryCode = countryCode || null;
    this.countryCode$$.next(countryCode);
  }

  openTermsHref() {
    const termsLink = this.countryCode && termsLinks.find((termLink) => termLink.countryCode === this.countryCode);
    const path = (termsLink && termsLink.path) || this.DEFAULT_PATH;
    return `https://www.mars.com${path}`;
  }
}
