import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DomService } from '../dom/dom.service';
import { getOneTrustConfig } from './config/one-trust';
import * as trackingMap from './config/tracking-map';

// One Trust global variables
declare global {
  interface Window {
    /** A callback function called by OneTrust to let you check `OptanonActiveGroups` and do something accordingly. */
    OptanonWrapper: () => void;

    /** A string representing the state of the cookie settings for the current user. */
    OptanonActiveGroups: string;
  }
}

// Google Tag Manager global variable
declare global {
  interface Window {
    dataLayer: Array<any>; // tslint:disable-line: no-any
  }
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private oneTrustLoaded$$ = new BehaviorSubject(false);

  oneTrustLoaded$ = this.oneTrustLoaded$$.asObservable();

  constructor(
    private domService: DomService,
    private activatedRoute: ActivatedRoute,
  ) {}

  loadOneTrust(): void {
    this.domService.loadScript(getOneTrustConfig(environment.production)).subscribe((success) => {
      if (success) {
        this.oneTrustLoaded$$.next(true);
      }
    });
  }

  loadGoogleTagManager(language: string): void {
    this.initDataLayer();
    this.activatedRoute.queryParamMap
      .pipe(
        map((params) => params.get('redirect_uri')),
        filter((redirectUri) => !!redirectUri),
        take(1),
        tap((redirectUri) => this.pushToDataLayer(trackingMap.getDataLayerLoadedEventData(language, redirectUri))),
      )
      .subscribe();
  }

  initDataLayer(): void {
    window.dataLayer = window.dataLayer || [];
  }

  pushToDataLayer(data: unknown): void {
    window.dataLayer.push(data);
  }

  pushEvent(eventType: trackingMap.TrackingEventType): void {
    const data = trackingMap.pushEvent(eventType);
    if (data) {
      this.pushToDataLayer(data);
    }
  }
}
