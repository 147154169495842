import { ValidatorFn } from '@angular/forms';

export enum ValidatorPropName {
  phoneNumber = 'validatePhoneNumber',
  required = 'required',
  incorrect = 'incorrect',
  OTCCodeError = 'otcCodeError',
}

export function phoneRequired(): ValidatorFn {
  return (phoneControl) => {
    if (!phoneControl.value?.phone || !phoneControl.value?.countryCode) {
      return {
        [ValidatorPropName.required]: {
          valid: false,
        },
      };
    }
    return null;
  };
}
