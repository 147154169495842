import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-terms-kr-privacy-collect',
  styleUrls: ['detail-terms-kr-privacy-collect.component.scss'],
  template: `
    <ul>
      <li>
        <h4>{{ 'tickbox_korea-tickbox1-subtitle-1' | translate }}</h4>
        <p>
          {{ 'tickbox_korea-tickbox1-subtitle-1-text' | translate }}
        </p>
      </li>
      <li>
        <h4>{{ 'tickbox_korea-tickbox1-subtitle-2' | translate }}</h4>
        <p>
          {{ 'tickbox_korea-tickbox1-subtitle-2-text' | translate }}
        </p>
      </li>
      <li>
        <h4>{{ 'tickbox_korea-tickbox1-subtitle-3' | translate }}</h4>
        <p class="text-highlight">
          {{ 'tickbox_korea-tickbox1-subtitle-3-text-bigger' | translate }}
        </p>
        <p>
          {{ 'tickbox_korea-tickbox1-subtitle-3-text' | translate }}
        </p>
      </li>
    </ul>
    <p>{{ 'tickbox_korea-tickbox1-text' | translate }}</p>
  `,
  standalone: true,
  imports: [TranslateModule],
})
export class DetailTermsKRPrivacyCollectComponent {}
