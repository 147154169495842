import { CustomSocial } from '@app/core/models/custom-social';
import { ErrorTextsDisplayed, SpecificCountryConsents } from 'src/app/core/models';
import { TrackingEnv } from 'src/app/core/services/tracking/tracking.env';
import { LoginType } from 'src/app/shared/utils/enums/login-type.enum';

export enum AppType {
  RC = 'royal-canin',
  WL = 'wl-default',
  MVR = 'wl-my-vet-reco',
}

export enum AppTitle {
  ROYAL_CANIN = 'Royal Canin',
  WHITE_LABEL_DEFAULT = 'White Label',
  MY_VET_RECO = 'MyVetReco',
}
export interface IEnvironment {
  contactSupportUrls: Record<string, string>;
  languagesCombinations?: Record<string, string>;
  production: boolean;
  ciamAPIUrl: string;
  apimAPIUrl: string;
  myAccountBffUrl: string;
  OcpApimSubscriptionKey: string;
  globalRedirectUri: string;
  specificLoginTypes: Record<string, LoginType[]>;
  excludedFromSocials: {
    clientIds: string[];
    countries: string[];
    languages: string[];
    combinations: Array<{ clientId: string; country: string }>;
  };
  excludeCookieBanner?: {
    clientIds: string[];
  };
  trackingEnv: TrackingEnv;
  okta: {
    domain: string;
    clientId: string;
    socialIdp: {
      facebook: string;
      google: string;
      paypal: string;
      apple: string;
      kakao: string;
    };
  };
  appType: AppType;
  isWhiteLabel: boolean;
  whiteLabelConfiguration?: {
    footer?: string;
  };
  specificCountryCheckboxesConsents: SpecificCountryConsents[];
  handleDisplayedTextInErrorPage: Record<string, ErrorTextsDisplayed>;
  includedCustomSocials: Record<string, CustomSocial>;
  hideContactSupport: string[];
}
