import { TrackingService } from '@app/core/services/tracking/tracking.service';
import { ErrorContext, RegisterUser } from '@app/core/models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EnvironmentService } from '@app/core/environment.service';
import { RegisterService } from '@app/core/services';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { buildMailtoHref, getContactLink, getContactSupportEmails, hasContactLink } from 'src/app/core/config/contact-support';
import { getSearchParamFromUrl } from '@app/shared/utils';
import { ContactSupportService } from '@app/core/services/contact-support/contact-support.service';
import { RCFieldModule, RCButtonModule } from '@rc/ui';
import { TrackingDirective } from '@app/core/services/tracking/tracking.directive';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { NgIf } from '@angular/common';
import { ContactUsButtonComponent } from '@app/shared/components/contact-us/contact-us-button/contact-us-button.component';

@Component({
  selector: 'app-register-page-success',
  templateUrl: './register-page-primary-phone-success.component.html',
  styleUrls: ['./register-page-primary-phone-success.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    TrackingDirective,
    FormsModule,
    ReactiveFormsModule,
    RCFieldModule,
    RCButtonModule,
    TranslateModule,
    ContactUsButtonComponent,
  ],
})
export class RegisterPagePrimaryPhoneSuccessComponent implements OnInit {
  user: RegisterUser;
  redirectUri: string;
  mailto: string;
  contactLink: string;
  ciamSupportEmails: string[];
  registerSuccessForm;
  codeVerified = false;
  loginRedirect: string;
  error: string;
  user_id: string;
  submitted = false;
  constructor(
    private contactSupportService: ContactSupportService,
    public route: Router,
    private formBuilder: UntypedFormBuilder,
    private registerService: RegisterService,
    private translateService: TranslateService,
    public env: EnvironmentService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.buildRegisterSuccessForm();

    /*
      // Here's the expected data structure:
      history.state.data = {
        callback: 'https://account.royalcanin.com',
        locale: 'fr_FR',
        profile: {
          firstName: 'Stéphane',
          lastName: 'Francel',
          email: 'sfrancel@yopmail.com',
          login: 'sfrancel@yopmail.com',
          locale: 'fr_FR',
          preferredLanguage: 'fr-FR',
          countryCode: 'FR'
        },
        credentials: {
          password: {
            value: '2020@Pazword'
          }
        }
      };
      */
    this.user = history?.state?.data;
    this.user_id = history?.state?.user_id;
    this.ciamSupportEmails = getContactSupportEmails();
    const { subject, body } = this.contactSupportService.buildEmail({
      context: ErrorContext.REGISTER,
      redirectUri: this.user?.callback,
      userLocale: this.user?.locale,
      clientId: getSearchParamFromUrl('client'),
      apiCodeError: '',
      apiUrlError: '',
      apiErrorReturn: '',
    });
    this.contactLink = getContactLink(this.user?.profile?.countryCode, this.redirectUri?.includes('vetfocus'));
    this.mailto = buildMailtoHref(this.ciamSupportEmails, subject, body);
    this.loginRedirect = this.redirectUri || `${origin}/implicit/login`;
    this.sendVerificationCode();
  }

  clickContact(): void {
    if (this.redirectUri?.includes('vetfocus') || hasContactLink(this.user?.profile?.countryCode)) {
      this.openUrl(this.contactLink);
    } else {
      this.openUrl(this.mailto);
    }
  }

  openUrl(urlString: string): void {
    if (urlString) {
      window.location.href = urlString;
    }
  }
  buildRegisterSuccessForm(): void {
    this.registerSuccessForm = this.formBuilder.group({
      code: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }
  onSubmit(): void {
    this.submitted = true;
    const activationPhoneBody = {
      locale: this.user?.locale,
      passcode: this.registerSuccessForm.get('code').value,
      login: this.user?.profile?.login,
      callback: this.user?.callback,
    };
    this.registerService
      .activatePhoneUser(activationPhoneBody)
      .pipe(
        tap((resp) => {
          this.codeVerified = true;
          window.setTimeout(() => {
            // Move to a new location or you can do something else
            window.location.href = resp.activation_link;
          }, 3000);
        }),
        catchError(() => {
          this.submitted = false;
          this.trackingService.pushEvent('registration.passcodeCheckError');
          this.error = this.translateService.instant('PhoneValidation_OTCCodeError');
          return EMPTY;
        }),
      )
      .subscribe();
  }
  sendVerificationCode(): void {
    this.registerService
      .sendNewPhoneCode(this.user_id)
      .pipe(
        tap(() => {
          this.registerSuccessForm.reset();
          this.registerSuccessForm.enable();
          this.submitted = false;
        }),
        catchError(() => {
          this.error = this.translateService.instant('PhoneValidation_OTCCodeError');
          return EMPTY;
        }),
      )
      .subscribe();
  }
  goToLogin(): void {
    window.location.href = this.loginRedirect;
  }
}
