import { Component, OnDestroy, OnInit } from '@angular/core';
import { RcAlertModule } from '@rc/ui';
import { MessageService } from '@app/core/services/message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from '@app/shared/components/message/message';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [RcAlertModule, NgForOf, AsyncPipe, TranslateModule],
})
export class MessageComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  messages: Message[] = [];
  constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.subscribeToAddMessage();
    this.subscribeToClearMessage();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  closeAlertById(id: string) {
    this.messages = this.messages.filter((message: Message) => id !== message.id);
  }

  private subscribeToAddMessage() {
    this.messageService.addMessage$.pipe(takeUntil(this.unsubscribe)).subscribe((message: Message) => {
      this.messages.push(message);
    });
  }

  private subscribeToClearMessage() {
    this.messageService.clearMessage$.pipe(takeUntil(this.unsubscribe)).subscribe((id: string | null) => {
      if (id) {
        this.closeAlertById(id);
      } else {
        this.messages = [];
      }
    });
  }
}
