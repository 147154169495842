import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/shared/providers/window.provider';

import { STORAGE_PREFIX } from './storage.types';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly local = this.window.localStorage;
  private readonly session = this.window.sessionStorage;

  constructor(@Inject(WINDOW) private window: Window) {}

  setSession<T>(key: string, value: T): void {
    this.set(this.session, key, value);
  }

  getSession<T>(key: string): T | null {
    return this.get(this.session, key);
  }

  removeSession(key: string): void {
    this.remove(this.session, key);
  }

  setLocal<T>(key: string, value: T): void {
    this.set(this.local, key, value);
  }

  getLocal<T>(key: string): T | null {
    return this.get(this.local, key);
  }

  removeLocal(key: string): void {
    this.remove(this.local, key);
  }

  private set<T>(storage: Storage, key: string, value: T): void {
    storage.setItem(this.getKey(key), JSON.stringify(value));
  }

  private get<T>(storage: Storage, key: string): T | null {
    const value = storage.getItem(this.getKey(key));
    if (value === null) {
      return null;
    }
    return JSON.parse(value);
  }

  private remove(storage: Storage, key: string): void {
    storage.removeItem(this.getKey(key));
  }

  private getKey(key: string): string {
    return STORAGE_PREFIX + key;
  }
}
