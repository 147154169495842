import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from '../../../environments/environment';
import { getIssuerUrl } from './auth.utils';

const issuer = getIssuerUrl();
const { clientId } = environment.okta;

const scopes = ['openid', 'profile', 'email'];

const pkce = true;

export const oktaConfigProvider = () => {
  const document = inject(DOCUMENT);
  const redirectUri = `${document.location.origin}/implicit/callback`;
  const oktaAuth = new OktaAuth({
    issuer,
    clientId,
    redirectUri,
    scopes,
    pkce,
    restoreOriginalUri: async (_, originalUri) => {
      document.defaultView?.location?.replace(originalUri);
    },
  });

  return {
    oktaAuth,
  };
};
