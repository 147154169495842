import { Injectable } from '@angular/core';
import { getSearchParamFromUrl } from '@app/shared/utils';
import { LoginType } from '@app/shared/utils/enums/login-type.enum';
import { environment } from 'src/environments/environment';
import { AppTitle, AppType } from 'src/environments/environment.interface';
import { SpecificCountryConsents } from './models';
import { ErrorTextsDisplayed } from 'src/app/core/models';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  public readonly isWhiteLabel = environment.isWhiteLabel;
  public readonly isWhiteLabelDefault = environment.appType === AppType.WL;
  public readonly isWhiteLabelMyVetReco = environment.appType === AppType.MVR;
  public readonly isRoyalCanin = environment.appType === AppType.RC;

  getTranslationPrefix(): 'wl.' | 'myvetreco.' | '' {
    switch (environment.appType) {
      case AppType.WL:
        return 'wl.';
      case AppType.MVR:
        return 'myvetreco.';
      default:
        return '';
    }
  }

  getWhileLabelFooter(): string {
    return environment.whiteLabelConfiguration?.footer;
  }

  getGlobalRedirectURI(): string {
    return environment.globalRedirectUri;
  }

  getAppTitle(): AppTitle {
    switch (environment.appType) {
      case AppType.WL:
        return AppTitle.WHITE_LABEL_DEFAULT;
      case AppType.MVR:
        return AppTitle.MY_VET_RECO;
      default:
        return AppTitle.ROYAL_CANIN;
    }
  }

  getSpecificConsentsFromEnv(): SpecificCountryConsents[] {
    return environment.specificCountryCheckboxesConsents;
  }

  getSpecificConsentsFiltredByCountry(countryCode?: string): SpecificCountryConsents[] {
    return this.getSpecificConsentsFromEnv()?.filter(
      (specificCountryConsents) => !specificCountryConsents.countryCode || specificCountryConsents.countryCode === countryCode,
    );
  }

  getLoginType(): LoginType[] {
    const clientId = getSearchParamFromUrl('client');
    return environment?.specificLoginTypes[clientId] || [LoginType.email];
  }

  getUrlContactSupport(clientId?: string): string {
    const client = clientId ? clientId : getSearchParamFromUrl('client');
    return environment.contactSupportUrls[client];
  }

  getDisplayedTextInErrorPageFromEnv(clientId = ''): ErrorTextsDisplayed {
    return environment.handleDisplayedTextInErrorPage[clientId] ?? environment.handleDisplayedTextInErrorPage['DEFAULT'];
  }
}
