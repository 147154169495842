import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MissingTranslationHandlerCustom implements MissingTranslationHandler {
  /**
   *
   * @param params
   * Contains a 'key' field to identify a translation key
   * @returns
   * Custom translation string, default to empty
   */
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}
