import { Component, Input } from '@angular/core';
import { EnvironmentService } from 'src/app/core/environment.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class LoaderComponent {
  @Input() relativePosition = false;
  @Input() noMobileDisplay = false;

  constructor(public env: EnvironmentService) {}
}
