import { Directive, HostListener, Input } from '@angular/core';
import { TrackingEventType } from './config/tracking-map';
import { TrackingService } from './tracking.service';

@Directive({
  selector: '[appTracking]',
  standalone: true,
})
export class TrackingDirective {
  @Input() appTracking: TrackingEventType;

  constructor(private trackingService: TrackingService) {}

  @HostListener('click')
  onClick(): void {
    this.trackingService.pushEvent(this.appTracking);
  }
}
