import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterPageEmailSuccessComponent } from './components/register-page-email-success/register-page-email-success.component';
import { RegisterPagePrimaryPhoneSuccessComponent } from './components/register-page-primary-phone-success/register-page-primary-phone-success.component';
import { CanAccessRegisterFormGuard } from './container/register-page-container/can-access-register-form.guard';
import { RegisterPageContainerComponent } from './container/register-page-container/register-page-container.component';
import { RegisterDataResolver } from './resolvers/register-data.resolver';
import { RedirectUriGuard } from '@app/guards/redirect-uri.guard';

const routes: Routes = [
  {
    path: '',
    component: RegisterPageContainerComponent,
    canActivate: [CanAccessRegisterFormGuard, RedirectUriGuard],
    resolve: { data: RegisterDataResolver },
  },
  { path: 'register/email/success', component: RegisterPageEmailSuccessComponent },
  { path: 'register/primary-phone/success', component: RegisterPagePrimaryPhoneSuccessComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegisterPageRoutingModule {}
