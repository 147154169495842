import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/core/environment.service';
import { map, tap } from 'rxjs/operators';
import { RegisterDataResolverModel } from '../../models/register-data-resolver.model';

@Component({
  selector: 'app-register-page-container',
  templateUrl: './register-page-container.component.html',
  styleUrls: ['./register-page-container.component.scss'],
})
export class RegisterPageContainerComponent implements OnInit {
  origin: string;
  callback: string;
  loginRedirect: string;
  sub: Subscription;
  clientId: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    public env: EnvironmentService
  ) {}

  /**
   * 1 - Translate title
   * 2 - Handle query params :
   *    - redirect to rc.com if missing redirect_uri
   *    - set callback for registration process
   *    - set origin for go back button
   *    - set login redirect for login button
   */
  ngOnInit(): void {
    const title = this.translateService.instant('loginPage_subtitleLink');
    this.titleService.setTitle(title);
    this.extractRouterData();
  }

  public goToLogin(): void {
    window.location.href = this.loginRedirect;
  }

  private extractRouterData(): void {
    this.activeRoute.data
      .pipe(
        map((routeData) => routeData.data),
        tap(({ loginRedirect, callback, origin, clientId }: RegisterDataResolverModel) => {
          this.loginRedirect = loginRedirect;
          this.callback = callback;
          this.origin = origin;
          this.clientId = clientId;
        })
      )
      .subscribe();
  }
}
