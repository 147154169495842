<div class="message-container" *ngFor="let message of messages">
  <rc-alert
    [type]="message.type"
    [showCloseAction]="message.hideCloseAction !== true"
    [customIcon]="message.customIcon"
    (closeAlert)="closeAlertById(message.id)"
  >
    {{ message?.text || (message?.keyTrad || '' | translate) }}
  </rc-alert>
</div>
