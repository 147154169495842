import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/environment.service';

@Injectable({
  providedIn: 'root',
})
export class CanAccessRegisterFormGuard implements CanActivate {
  constructor(private environmentService: EnvironmentService) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.environmentService.isWhiteLabelMyVetReco) {
      window.location.href = this.environmentService.getGlobalRedirectURI();
      return false;
    }
    return true;
  }
}
