import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './core/auth/auth-callback/auth-callback.component';
import { RootErrorPageComponent } from './pages/error-page/root-error-page/root-error-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/register-page/register-page.module').then((mod) => mod.RegisterPageModule),
  },
  {
    path: 'activate',
    loadChildren: () => import('./pages/activate-page/activate-page.module').then((mod) => mod.ActivatePageModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/set-password-page/set-password-page.module').then((mod) => mod.SetPasswordPageModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule),
  },
  {
    path: 'implicit/callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'error',
    component: RootErrorPageComponent,
    canActivate: [],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
