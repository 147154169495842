import { environment } from 'src/environments/environment';
import { IDataLayerAPISubmitErrorEvent, IDataLayerButtonClickEvent, IDataLayerCiamActionEvent, IDatalayerLoaded } from '../tracking';

export const getDataLayerLoadedEventData = (phraseLanguage: string, redirectUri: string): IDatalayerLoaded => {
  return {
    event: 'datalayerLoaded',
    OKTAClientID: redirectUri || 'https://royalcanin.com',
    site: {
      environment: environment.trackingEnv,
      id: 'RCGlobalMS4',
      country: 'Global',
      locale: phraseLanguage,
    },
  };
};

export const getCiamActionEventData = (name: string): IDataLayerCiamActionEvent => ({
  event: 'CIAMaction',
  CIAMaction: { name },
});

export const getApiErrorEventData = (name: string): IDataLayerAPISubmitErrorEvent => ({
  event: 'APISubmitError',
  APISubmitError: { formName: name },
});

export const getButtonClickEventData = (name: string): IDataLayerButtonClickEvent => ({
  event: 'buttonClick',
  buttonClick: { name },
});

export type TrackingEventType =
  | 'registration.loginClick'
  | 'registration.verifiedAccountSuccess'
  | 'registration.continueSuccess'
  | 'registration.continueError'
  | 'registration.contactSupport'
  | 'registration.sendVerificationCode'
  | 'registration.passcodeCheckError'
  | 'registration.socialLoginFacebookClick'
  | 'registration.socialLoginGoogleClick'
  | 'registration.socialLoginPaypalClick'
  | 'registration.socialLoginAppleClick'
  | 'registration.socialLoginKakaoClick'
  | 'resetPassword.loginClick'
  | 'resetPassword.success'
  | 'resetPassword.passcodeCheckError'
  | 'resetPassword.error';

export const pushEvent = (
  eventType: TrackingEventType,
): IDataLayerCiamActionEvent | IDataLayerAPISubmitErrorEvent | IDataLayerButtonClickEvent | void => {
  const mapping = {
    // ---- Registration ----
    'registration.loginClick': getButtonClickEventData('Go to login'),
    'registration.verifiedAccountSuccess': getButtonClickEventData('Account verified - redirection'),
    'registration.continueSuccessEmail': getCiamActionEventData('Account created - email - send verification email'),
    'registration.continueSuccessPhone': getCiamActionEventData('Account created - phone - display phone number verification'),
    'registration.continueSuccessUsername': getCiamActionEventData('Account created - username - display phone number verification'),
    'registration.continueError': getApiErrorEventData('Account creation'),
    'registration.passcodeCheckError': getApiErrorEventData('Account creation passcode check'),
    'registration.contactSupport': getButtonClickEventData('Contact support'),
    'registration.sendVerificationCode': getButtonClickEventData('Send new code'),
    'registration.socialLoginFacebookClick': getButtonClickEventData('Connect with Facebook'),
    'registration.socialLoginGoogleClick': getButtonClickEventData('Connect with Google'),
    'registration.socialLoginPaypalClick': getButtonClickEventData('Connect with Paypal'),
    'registration.socialLoginAppleClick': getButtonClickEventData('Connect with Apple'),
    'registration.socialLoginKakaoClick': getButtonClickEventData('Connect with Kakao'),
    // ---- Reset password ----
    'resetPassword.loginClick': getButtonClickEventData('Password reset - force redirection'),
    'resetPassword.success': getCiamActionEventData('Password reset'),
    'resetPassword.error': getApiErrorEventData('Password reset error'),
    'resetPassword.passcodeCheckError': getApiErrorEventData('Password reset passcode check'),
  };

  if (mapping[eventType]) {
    return mapping[eventType];
  } else {
    console.error('pushEvent: Invalid eventType', eventType);
  }
};
