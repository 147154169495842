import { DOCUMENT, LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(@Inject(DOCUMENT) private document: Document, private locationStrategy: LocationStrategy) {}

  private get baseUrl(): string {
    return this.document.location.origin + this.locationStrategy.getBaseHref();
  }

  getFullUrl(path = ''): string {
    if (/^\//.test(path)) {
      throw new Error(`LocationService.getFullUrl: "path" should NOT start with a slash (${path})`);
    }
    return this.baseUrl + path;
  }
}
