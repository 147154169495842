export enum IconType {
  Calculator = 'calculator',
  Consultation = 'consultation',
  Animal = 'animal',
  Medical = 'medical-record',
  Directory = 'directory',
  Reset = 'reset',
  BodyScore = 'body-score',
  Weight = 'weight',
  WeightTracking = 'weight-tracking',
  profile = 'pet_profile',
  Food = 'food',
  Pathologies = 'pathologies',

  /* Segmented control: Icon */
  SegmentedWeight = 'segmented-weight',
}
