<div class="set-pwd-failure__main-container">
  <div
    class="set-pwd-failure__main-container__img-container"
    [ngClass]="{ 'set-pwd-failure__main__img-bloc--myvetreco': env.isWhiteLabelMyVetReco }"
  >
    <img src="./assets/images/reset_password_error.jpg" alt="" />
  </div>
  <div class="set-pwd-failure__main-container__text-container">
    <h1 class="set-pwd-failure__main-container__text-container__h1">{{ 'setPasswordFailure_title' | translate }}</h1>
    <p class="set-pwd-failure__main-container__text-container__content-p">{{ 'setPasswordFailure_content' | translate }}</p>
    <div class="set-pwd-failure__main-container__text-container__bottom">
      <button
        (click)="retryReset()"
        class="rc-button rc-button--outline-regular set-pwd-failure__main-container__text-container__bottom__button1"
      >
        <span class="rc-button__title">{{ 'setPasswordFailure_buttonTryAgain' | translate }}</span>
      </button>
      <button class="rc-button rc-button--outline-regular set-pwd-failure__main-container__text-container__bottom__button2">
        <a [href]="mailto" class="rc-button__title">{{ 'setPasswordFailure_buttonSupport' | translate }}</a>
      </button>
    </div>
  </div>
</div>
